import { Icon } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';

export default function NoSectionsMsg() {
  const { t } = useTranslation();

  return (
    <div className="d-flex align-items-center justify-content-center">
      <div style={{ maxWidth: 500, marginTop: 70, marginBottom: 65 }}>
        <Icon name="information" color="primary" size="61px" />
        <h3 className="text-medium fw-600 mt-4 mb-3 fs-18 w-100">
          {t(`attendanceTracking.noSectionsMsg.title`)}
        </h3>
        <p className="text-medium mb-5 fs-18 w-100" style={{ maxWidth: 325 }}>
          {t(`attendanceTracking.noSectionsMsg.subtitle`)}
        </p>
      </div>
    </div>
  );
}
