import { useEffect, useState } from 'react';

import { getClassAttendance, saveAttendants } from '../api/requests/attendance';
import { AttendantRecord } from '../types/Class';

interface Params {
  classId: number;
  timeModulesIds: number[];
}

export default function useAttendanceList({ classId, timeModulesIds }: Params) {
  const [isLoading, setIsLoading] = useState(false);
  const [attendanceList, setAttendanceList] = useState<any[]>([]);
  const [error, setError] = useState(false);

  const fetchAttendanceLists = async (setLoader: boolean = true) => {
    if (setLoader) {
      setIsLoading(true);
    }

    setError(false);

    let attendanceListRequests: Promise<any>[] = [];

    timeModulesIds.forEach((id) => {
      attendanceListRequests.push(
        getClassAttendance({ moduleId: id, classId }),
      );
    });

    const responses = await Promise.all(attendanceListRequests);

    if (responses.some((response) => response.error)) {
      setError(true);
      return;
    }

    let newList: AttendantRecord[] = [];

    responses.forEach((response) => {
      newList = [...newList, ...response.data?.data];
    });

    setAttendanceList(newList);
    setIsLoading(false);
  };

  useEffect(() => {
    if (classId) {
      fetchAttendanceLists();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classId, timeModulesIds]);

  const updateAttendance = async (attendants: AttendantRecord[]) => {
    const res = await saveAttendants(attendants);

    if (res.error) setError(true);

    await fetchAttendanceLists(false);
  };

  return {
    isLoading,
    attendanceList,
    error,
    updateAttendance,
  };
}
