import { useTranslation } from 'react-i18next';
import { clean, validate } from 'rut.js';
import validator from 'validator';

export const useValidations = () => {
  const { t } = useTranslation();
  const prefixValidations = 'common.validations';
  const msgValidations = {
    required: t(`${prefixValidations}.required`),
    invalidPhone: t(`${prefixValidations}.invalidPhone`),
    invalidEmail: t(`${prefixValidations}.invalidEmail`),
    invalidRut: t(`${prefixValidations}.invalidRut`),
    invalidBeforeDate: t(`${prefixValidations}.invalidBeforeDate`),
    invalidAlphaNumeric: t(`${prefixValidations}.invalidAlphanumeric`),
    invalidPassword: t(`${prefixValidations}.invalidPassword`),
    invalidRepeatPassword: t(`${prefixValidations}.invalidRepeatPassword`),
    invalidMinLength: (length: number) =>
      t(`${prefixValidations}.minLength`, { length: length }),
  };

  /**
   * Valida el formato de la contraseña
   * @param password
   * @returns
   */
  const validatePassword = (password: string) => {
    return password.length >= 8 ? undefined : msgValidations.invalidPassword;
  };

  /**
   * Esta funcion retorna otra funcion de validacion que retorna si la password pasada por parametros es igual a la contraseña repetida
   * @param password
   * @returns
   */
  const validateRepeatPassword = (password: string) => (
    repeatPassword: string,
  ) => {
    return password === repeatPassword
      ? undefined
      : msgValidations.invalidRepeatPassword;
  };

  /**
   * Valida que telefono tenga formato +XXXXXXXXXXX
   * @param phone
   * @returns
   */
  const validatePhone = (phone: string | undefined) => {
    if (phone && phone !== '') {
      const cleanPhone = phone.replaceAll(' ', '');
      if (!/^\+[0-9]{11,15}$/.test(cleanPhone)) {
        return msgValidations.invalidPhone;
      }
    }
    return undefined;
  };

  /**
   * Valida que el texto ingresado tenga formato de correo
   * @param email
   * @returns
   */
  const validateEmail = (email: string | undefined) => {
    if (email && !validator.isEmail(email)) {
      return msgValidations.invalidEmail;
    }
    return undefined;
  };

  /**
   * Función que valida que el texto ingresado no sea vacío
   * y que no contenga sólo espacios.
   * @param value
   * @returns
   */
  const validateTextNotEmpty = (value: any) => {
    if (value === null || value === undefined) {
      return msgValidations.required;
    }

    if (typeof value === 'string' && value.trim().length === 0) {
      return msgValidations.required;
    }

    return undefined;
  };

  /**
   * Función que valida que el texto ingresado sea un rut válido
   * @param value
   * @returns
   */
  const validateRut = (value: any) => {
    if (value && typeof value === 'string' && !validate(clean(value))) {
      return msgValidations.invalidRut;
    }
    return undefined;
  };

  /**
   * Función que valida que el texto solo tenga números y letras
   * @param value
   * @returns
   */
  const validateAlphaNumeric = (value: string | undefined) => {
    if (!value || !/^[a-zA-Z0-9]+$/.test(value)) {
      return;
    }
    return undefined;
  };

  /**
   * Función que valida que la fecha de fin sea mayor que la de inicio
   * @param endDate
   * @returns
   */
  const validateBeforeDate = (endDate: string) => (
    value: string | undefined,
  ) => {
    if (value && endDate) {
      if (Date.parse(endDate) < Date.parse(value)) {
        return msgValidations.invalidBeforeDate;
      }
    }
    return undefined;
  };

  /**
   * Recibe un numero de caracteres y retorna otra funcion que valida la longitud minima del string ingresado
   * @param minLength
   * @returns
   */
  const validateMinLength = (minLength: number) => {
    return (value?: any) =>
      value && typeof value === 'string' && value?.length < minLength
        ? msgValidations.invalidMinLength(minLength)
        : undefined;
  };

  return {
    validatePassword,
    validateRepeatPassword,
    validatePhone,
    validateEmail,
    validateTextNotEmpty,
    validateRut,
    msgValidations,
    validateBeforeDate,
    validateAlphaNumeric,
    validateMinLength,
  };
};
