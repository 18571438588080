import { Button, Icon, Modal } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';

interface Props {
  isOpen: boolean;
  onConfirm?: () => void;
  onCancel?: () => void;
  onDialogClose?: () => void;
}

export default function ConfirmStartDialog({
  isOpen,
  onConfirm = () => null,
  onCancel = () => null,
  onDialogClose = () => null,
}: Props) {
  const { t } = useTranslation();

  return (
    <Modal
      size="md"
      isOpen={isOpen}
      toggle={() => null}
      onClosed={onDialogClose}
    >
      <div className="text-center">
        <Icon name="warning" size="52px" />
        <p
          className="mt-5 mb-2"
          style={{
            color: '#000',
            fontSize: '22px',
          }}
        >
          {t('classesHistory.classConflictsNotification.description')}
        </p>
        <p className="fs-18 mb-5">
          {t('classesHistory.classConflictsNotification.question')}
        </p>
        <div
          className="d-flex justify-content-between"
          style={{ gap: '1.25rem' }}
        >
          <Button
            fullwidth
            onClick={() => onCancel()}
            text={t(`common.btnCancel`)}
            outlined
          />
          <Button
            fullwidth
            onClick={() => onConfirm()}
            text={t(`common.btnConfirm`)}
            className="mt-0"
          />
        </div>
      </div>
    </Modal>
  );
}
