import {
  CellFormatOptions,
  ColumnTable,
  DateInput,
  Icon,
  TextInput,
  TextOutlinedInput,
} from '@octano/global-ui';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { showDialogInfo } from '../../../components/Modals/Dialog';
import { PartialEvaluation } from '../types';

export interface PartialEvaluationGradeColumnsProps {
  onChange: (state: PartialEvaluation[]) => void;
  error?: boolean;
  partialEvaluations: PartialEvaluation[];
  onNameChange: (row: { name?: string }) => (e: any) => void;
  onDateChange: (row: { date?: string | Date | null }) => (e: any) => void;
  onWeightingChange: (row: { percentage?: string }) => (e: any) => void;
}

export default function usePartialEvaluationGradeColumns({
  onChange,
  error,
  partialEvaluations,
  onNameChange,
  onDateChange,
  onWeightingChange,
}: PartialEvaluationGradeColumnsProps) {
  const { t } = useTranslation();
  const isExisingtNameDuplicate = useCallback(
    (index: number, name: string) => {
      let errorText = partialEvaluations.find((partialEvaluation, key) => {
        if (key !== index) {
          if (!partialEvaluation.name) {
            return false;
          }
          return partialEvaluation.name === name;
        }
        return false;
      });
      if (errorText) {
        return `*${t(
          'gradeEntry.partialEvaluationGradeEntry.duplicationName',
        )}`;
      }
      return undefined;
    },
    [partialEvaluations, t],
  );

  const deletePartialEvaluationByIndex = useCallback(
    (index: number) => {
      return () => {
        if (partialEvaluations[index].canItBeRemove) {
          const newPartialEvaluations = [...partialEvaluations];
          newPartialEvaluations.splice(index, 1);
          onChange(newPartialEvaluations);
        } else {
          showDialogInfo({
            icon: 'warning',
            title: t('gradeEntry.partialEvaluationGradeEntry.dialogInfo.title'),
            subtitle: t(
              'gradeEntry.partialEvaluationGradeEntry.dialogInfo.subtitle',
            ),
          });
        }
      };
    },
    [t, partialEvaluations, onChange],
  );

  const columns = useMemo<ColumnTable[]>(() => {
    return [
      {
        columnName: 'evaluation',
        headerText: t(
          'gradeEntry.partialEvaluationGradeEntry.columns.evaluation',
        ),
        width: '160px',
        thClassName: 'text-center',
        tdProps: { noTdWrap: true },
        cellFormat: ({ row, index }: CellFormatOptions<PartialEvaluation>) => {
          let errorText =
            error && !row.name ? t('gradeEntry.require') : undefined;
          let limitOfChar =
            row.name && row.name.length > 30
              ? t('gradeEntry.partialEvaluationGradeEntry.charLimit')
              : undefined;
          return (
            <td colSpan={row.type !== 'normal' ? 2 : 1}>
              <TextInput
                key={index}
                name={`evaluation-${index as number}`}
                label={t(
                  'gradeEntry.partialEvaluationGradeEntry.columns.placeholder.nameEvaluation',
                )}
                type="text"
                value={row.name}
                errorText={
                  isExisingtNameDuplicate(
                    index as number,
                    row.name as string,
                  ) ||
                  limitOfChar ||
                  errorText
                }
                onChange={onNameChange(row)}
              />
            </td>
          );
        },
      },
      {
        columnName: 'date',
        headerText: t('common.terms.date'),
        width: '160px',
        thClassName: 'text-center',
        tdProps: { noTdWrap: true },
        cellFormat: (options: CellFormatOptions<PartialEvaluation>) => {
          if (options.row.type !== 'normal') {
            return null;
          }
          let errorText =
            error && !options.row.date ? t('gradeEntry.require') : undefined;
          return (
            <td>
              <DateInput
                name={`date-${options.index as number}`}
                label={t(
                  'gradeEntry.partialEvaluationGradeEntry.columns.placeholder.surrencerDate',
                )}
                value={options.row.date}
                onChange={onDateChange(options.row)}
                shouldUnregister={true}
                errorText={errorText}
              />
            </td>
          );
        },
      },
      {
        columnName: 'percentage',
        headerText: t(
          'gradeEntry.partialEvaluationGradeEntry.columns.percentage',
        ),
        width: '40px',
        thClassName: 'text-center',
        tdClassName: 'expander-height-input',
        cellFormat: (options: CellFormatOptions<PartialEvaluation>) => {
          let errorText =
            error && !options.row.percentage
              ? t('gradeEntry.require')
              : undefined;
          let value =
            options.row.percentage === '0' ? '' : options.row.percentage;
          return (
            <div className="d-flex align-items-center" style={{ gap: '8px' }}>
              <TextOutlinedInput
                type="text"
                name="percentage"
                placeholder="—%"
                value={value}
                onChange={onWeightingChange(options.row)}
                errorText={errorText}
              />
              <div
                onClick={deletePartialEvaluationByIndex(
                  options.index as number,
                )}
              >
                <Icon name="trash" className="mb-3" />
              </div>
            </div>
          );
        },
      },
    ];
  }, [
    t,
    onNameChange,
    onDateChange,
    onWeightingChange,
    isExisingtNameDuplicate,
    deletePartialEvaluationByIndex,
    error,
  ]);

  return columns;
}
