import dayjs from 'dayjs';
import { useState } from 'react';

import useInterval from './useInterval';

interface Params {
  refreshEvery: number;
  offset?: number;
}

const getCurrentDayjsDateTime = (offset?: number) => {
  if (offset) {
    return dayjs(new Date()).utc().utcOffset(offset);
  } else {
    return dayjs(new Date());
  }
};

export default function useCurrentTimeSubscription({
  offset = 0,
  refreshEvery,
}: Params) {
  const [currentTime, setCurrentTime] = useState(() =>
    getCurrentDayjsDateTime(offset),
  );

  useInterval(() => {
    setCurrentTime(getCurrentDayjsDateTime(offset));
  }, refreshEvery);

  return currentTime;
}
