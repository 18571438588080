import { ReactElement, useCallback } from 'react';

import DisplayError from '../../../components/Info/DisplayError';
import Loading from '../../../components/Info/Loading';
import { createFetchContext } from '../../../components/contexts/FetchContextFactory';
import { getSectionGradeConfig } from '../requests';
import {
  PartialEvaluation,
  PartialEvaluationSubevaluation,
  SectionsGradeConfig,
} from '../types';

export interface CreateEvaluationGradeEntryBaseLoaderProps<T> {
  sectionId: number | string;
  children: (props: { data: T; refresh: () => Promise<void> }) => ReactElement;
}

const { FetchProvider, FetchConsumer } = createFetchContext<
  undefined,
  SectionsGradeConfig
>();

export const CreateEvaluationGradeEntryBaseLoaderConsumer = FetchConsumer;

export default function CreateEvaluationGradeEntryBaseLoader({
  sectionId,
  children,
}: CreateEvaluationGradeEntryBaseLoaderProps<SectionsGradeConfig>) {
  const request = useCallback(async () => {
    const response = await getSectionGradeConfig(sectionId);

    if (response.error) {
      return { error: response.error };
    }

    const evaluations: PartialEvaluation[] = [];
    const groups: Record<
      number,
      Omit<PartialEvaluation, 'type'> & {
        grades: PartialEvaluationSubevaluation[];
      }
    > = {};
    for (const testGrade of response.data?.gradesConfig?.testGrades ?? []) {
      let canItBeRemove = testGrade.studentTestGrades
        ? !testGrade.studentTestGrades.some(({ grade }) => grade)
        : true;
      if (testGrade.group) {
        if (groups[testGrade.group.id]) {
          const group = groups[testGrade.group.id];
          if (!canItBeRemove) {
            group.canItBeRemove = false;
          }
          group.grades.push({
            id: testGrade.id,
            name: testGrade.name,
            date: testGrade.date,
            percentage: `${testGrade.percentage}`,
            canItBeRemove,
          });
        } else {
          const group: PartialEvaluation = {
            type: 'subevaluations',
            id: testGrade.group.id,
            name: testGrade.group.name,
            percentage: `${testGrade.group.percentage}`,
            canItBeRemove,
            grades: [
              {
                id: testGrade.id,
                name: testGrade.name,
                date: testGrade.date,
                percentage: `${testGrade.percentage}`,
                canItBeRemove,
              },
            ],
          };
          groups[testGrade.group.id] = group;
          evaluations.push(group);
        }
      } else {
        evaluations.push({
          type: 'normal',
          id: testGrade.id,
          name: testGrade.name,
          date: testGrade.date,
          percentage: `${testGrade.percentage}`,
          canItBeRemove,
        });
      }
    }
    return {
      data: {
        sectionData: response.data.sectionData,
        gradesConfig: {
          finalGrade: {
            id: response.data.gradesConfig.finalGrade?.id ?? undefined,
            partialPercentage: response.data.gradesConfig.finalGrade?.testGrade,
            examPercentage: response.data.gradesConfig.finalGrade?.finalTest,
          },
          testGrades: evaluations,
        },
      },
    };
  }, [sectionId]);

  return (
    <FetchProvider request={request} defaultQuery={undefined} fetchImmediately>
      <FetchConsumer>
        {({ data, loading, error, refresh }) => {
          if (error) {
            return (
              <DisplayError
                insideCard
                textBody={error.code}
                retryAction={refresh}
                loadingAction={loading}
              />
            );
          }

          if (loading) {
            return <Loading insideCard />;
          }

          if (!data) {
            return (
              <DisplayError
                insideCard
                textBody="Data no cargada"
                retryAction={refresh}
                loadingAction={loading}
              />
            );
          }

          return children({ data, refresh });
        }}
      </FetchConsumer>
    </FetchProvider>
  );
}
