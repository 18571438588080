import { Button, ColumnTable, Table, useMobile } from '@octano/global-ui';
import dayjs from 'dayjs';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import ClassTypeLegend from '../../../components/ClassTypeLegend/ClassTypeLegend';
import NoResultsMsg from '../../../components/NoResultsMsg/NoResultsMsg';
import useCurrentTimeSubscription from '../../../hooks/useCurrentTimeSubscription';
import useServerSettings from '../../../hooks/useServerSettings';
import { useUserState } from '../../../hooks/useUserState';
import { ActivityName } from '../../../types/Class';
import { SelectedClass } from './FutureClassesSearch';

interface Props {
  results: SelectedClass[];
  currentPage: number;
  totalPages: number;
  totalItems: number;
  resultsPerPage: number;
  isLoadingResults: boolean;
  onPageChange: (page: number) => void;
  onClassAdvance: (classData: SelectedClass) => void;
}

export default function FutureClassesTable({
  results,
  currentPage,
  totalPages,
  totalItems,
  resultsPerPage,
  isLoadingResults,
  onPageChange,
  onClassAdvance,
}: Props) {
  const isMobile = useMobile();
  const { t } = useTranslation();
  const { offset } = useServerSettings();
  const currentTime = useCurrentTimeSubscription({
    offset,
    refreshEvery: 15000,
  });

  const { userData } = useUserState();

  const canAdvanceClass = useCallback(
    (schedule: string, date: string) => {
      const [startTimeStr] = schedule.split(' - ');
      const [hours, minutes] = startTimeStr.split(':').map((n) => Number(n));
      const classStartTime = dayjs(date)
        .hour(hours)
        .minute(minutes)
        .second(0)
        .utcOffset(offset, true);

      return currentTime.isBefore(classStartTime);
    },
    [currentTime, offset],
  );

  const TABLE_COLUMNS: ColumnTable<SelectedClass>[] = useMemo(() => {
    if (isMobile) {
      return [
        {
          headerText: t(`common.terms.date`),
          columnName: 'date',
        },
        {
          headerText: t(`common.terms.modules`),
          columnName: 'modules',
        },
        {
          headerText: '',
          columnName: 'action',
          width: '180px',
          cellFormat: (data) => {
            const { schedule, date } = data.row;
            const disableBtn = !canAdvanceClass(schedule, date);

            return (
              <Button
                text={t(`common.btnAdvanceClass`)}
                outlined
                onClick={() => onClassAdvance(data.row)}
                size="sm"
                disabled={disableBtn}
              />
            );
          },
        },
      ];
    } else {
      return [
        {
          headerText: t(`common.terms.date`),
          columnName: 'date',
        },
        {
          headerText: t(`common.terms.modules`),
          columnName: 'modules',
        },
        {
          headerText: t(`common.terms.schedule`),
          columnName: 'schedule',
        },
        {
          headerText: t(`common.terms.activity`),
          columnName: 'activity',
          cellFormat: (data) => (
            <ClassTypeLegend activityName={data.row.activity as ActivityName} />
          ),
        },
        {
          headerText: t(`common.terms.responsability`),
          columnName: '123',
          cellFormat: (data) => {
            const isHeadTeacher = data.row.professors.some(
              (p) => p.account.teacherId === userData?.teacherId,
            );
            return isHeadTeacher
              ? t(`common.terms.headTeacher`)
              : t(`common.terms.classResponsible`);
          },
        },
        {
          headerText: '',
          columnName: 'action',
          width: '180px',
          cellFormat: (data: any) => {
            const { schedule, date } = data.row;
            const disableBtn = !canAdvanceClass(schedule, date);
            return (
              <Button
                text={t(`common.btnAdvanceClass`)}
                outlined
                onClick={() => onClassAdvance(data.row)}
                size="sm"
                disabled={disableBtn}
              />
            );
          },
        },
      ];
    }
  }, [isMobile, onClassAdvance, canAdvanceClass, t, userData]);

  return (
    <div className="text-center">
      <Table
        noResultsText={<NoResultsMsg />}
        columns={TABLE_COLUMNS}
        data={results}
        pagination={
          totalPages > 1
            ? {
                totalItems,
                totalPages,
                currentPage: currentPage + 1,
                itemsPerPage: resultsPerPage,
                onChangePage: (page) => onPageChange(page - 1),
              }
            : undefined
        }
        isLoadingResults={isLoadingResults}
        loadingView={{
          title: t(`startClasses.loadingTitles.title`),
          subtitle: t(`startClasses.loadingTitles.subtitle`),
        }}
      />
    </div>
  );
}
