import {
  Button,
  DisplayInfo,
  addToast,
  showDialogConfirm,
} from '@octano/global-ui';
import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { Col, Row } from 'reactstrap';
import { format as formatRut } from 'rut.js';

import {
  GetSectionsTeacherPortalByIDReponse,
  downloadPDFSectionClosed,
  getSectionsTeacherPortalByIDRequest,
  postClosedSectionTeacherPortalRequest,
} from '../../api/requests/teacherPortal';
import Loading from '../../components/Info/Loading';
import { PathsLayouts } from '../../config/routes';
import DetailBoxPeriodEndReview from './parts/DetailBox';
import TablePeriodEndReview from './parts/Table';

export default function PeriodEndReview() {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const [data, setData] = useState<GetSectionsTeacherPortalByIDReponse>();
  const [loading, setLoading] = useState<boolean>(true);
  const [btnLoading, setBtnLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>();
  const history = useHistory();

  const getData = useCallback(async () => {
    setLoading(true);
    const response = await getSectionsTeacherPortalByIDRequest(Number(id));
    if (response.error) {
      setError(response.error.code);
    } else if (response.data) {
      setError(null);
      setData(response.data);
    }
    setLoading(false);
  }, [id, setData, setLoading]);

  const downloadData = useCallback(
    async (name: string) => {
      setBtnLoading(true);
      const response = await downloadPDFSectionClosed(Number(id));
      if (response.error) {
        addToast({
          color: 'danger',
          icon: 'information',
          text: t(`periodEndReview.addToastDownload.error`),
        });
      } else if (response.data) {
        addToast({
          color: 'success',
          icon: 'check',
          text: t(`periodEndReview.addToastDownload.success`),
        });
        const a = document.createElement('a');
        document.body.appendChild(a);
        const url = window.URL.createObjectURL(response.data);
        a.href = url;
        a.download = `${name}.${response.data.type.split('/')[1]}`;
        a.click();
        setTimeout(() => {
          window.URL.revokeObjectURL(url);
          a.remove();
        }, 0);
      }
      setBtnLoading(false);
    },
    [id, t],
  );

  const getStudentsPending = useCallback(() => {
    return data?.students.filter((student) => !student.generalPassed) || [];
  }, [data]);

  const postData = useCallback(async () => {
    setBtnLoading(true);
    const { error } = await postClosedSectionTeacherPortalRequest(Number(id));
    if (error) {
      addToast({
        color: 'danger',
        icon: 'information',
        text: t(`periodEndReview.addToast.error`),
      });
    } else {
      addToast({
        color: 'success',
        icon: 'information',
        text: t(`periodEndReview.addToast.success`),
      });
      await getData();
    }
    setBtnLoading(false);
  }, [id, t, getData]);

  const onSumit = useCallback(async () => {
    const studentsPending = getStudentsPending();
    const prefix =
      studentsPending.length === 0
        ? 'notStudentsPending'
        : 'withStudentPending';
    showDialogConfirm({
      icon: {
        color: 'primary',
        name: 'warning',
      },
      title: t(`periodEndReview.modalConfirmToClose.${prefix}.title`),
      subtitle: t(`periodEndReview.modalConfirmToClose.${prefix}.subtitle`),
      children:
        studentsPending.length === 0 ? undefined : (
          <>
            <span>{t('periodEndReview.modalConfirmToClose.label')}</span>
            <ul className="box-ul-detail mb-4">
              {studentsPending.map((student) => (
                <li className="fs-16">
                  <p>
                    <span>{student.fullName} </span>
                    <span>
                      {student.run
                        ? formatRut(student.run)
                        : student.passportNumber}
                    </span>
                  </p>
                </li>
              ))}
            </ul>
          </>
        ),
      btnClose: { text: t(`common.btnCancel`) },
      btnConfirm: {
        text: t(`common.btnConfirm`),
        onConfirm: postData,
      },
    });
  }, [postData, t, getStudentsPending]);

  const back = useCallback(() => {
    history.push(PathsLayouts.periodEnd);
  }, [history]);

  useEffect(() => {
    getData();
  }, [getData]);

  if (loading) {
    return <Loading insideCard />;
  }

  return (
    <div className="m-3 py-4 px-3 bg-white rounded">
      <Row>
        <Col>
          <Button
            icon={'back'}
            text={t('common.btnGoBack')}
            size="sm"
            outlined
            rounded
            onClick={back}
            className={'mb-4'}
          />
        </Col>
        {!error && (
          <Col md={6}>
            <DetailBoxPeriodEndReview
              data={
                data?.section as GetSectionsTeacherPortalByIDReponse['section']
              }
            />
          </Col>
        )}
      </Row>
      <h2 className="text-primary fs-20 fw-700 mt-4 mb-2 text-uppercase">
        {t(`periodEndReview.title`)}
      </h2>
      <p className="fs-16 mt-3 mb-4">{t('periodEndReview.subtitle')}</p>
      {error ? (
        <DisplayInfo
          insideCard
          maxWidth="700px"
          iconName="information"
          title={t('periodEndReview.errorResult.title')}
          textBody={t('periodEndReview.errorResult.subtitle')}
        />
      ) : (
        <>
          {data?.section.closedAt && (
            <Row className="mb-3">
              <Col />
              <Col md={3}>
                <Button
                  outlined
                  fullwidth
                  icon={'download'}
                  text={t('periodEndReview.buttonDownload')}
                  loading={btnLoading}
                  onClick={() =>
                    downloadData(
                      `ActaDeCierre_${data.section.name}_${dayjs(
                        data.section.closedAt,
                      ).format('YYYY-MM-DD')}`,
                    )
                  }
                />
              </Col>
            </Row>
          )}
          <TablePeriodEndReview
            data={
              data?.students as GetSectionsTeacherPortalByIDReponse['students']
            }
            passingGrade={
              data?.passingGrade as GetSectionsTeacherPortalByIDReponse['passingGrade']
            }
            section={
              data?.section as GetSectionsTeacherPortalByIDReponse['section']
            }
          />
          {!data?.section.closedAt && (
            <Row className="mt-4 mb-4">
              <Col />
              <Col md={3}>
                <Button
                  text={t('periodEndReview.buttonConfirmToClose')}
                  fullwidth
                  onClick={onSumit}
                  loading={btnLoading}
                />
              </Col>
            </Row>
          )}
        </>
      )}
    </div>
  );
}
