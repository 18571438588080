export interface AddEvaluationGradeEntryFinalGradeCellProps {
  percentage: string;
  formattertNote: (value: string) => string;
  addtTextWithColor: (percentage: string) => JSX.Element;
  selectedNoteExamen: boolean;
  onChange: (value: string) => void;
}

export default function AddEvaluationGradeEntryFinalGradeCell({
  percentage,
  formattertNote,
  addtTextWithColor,
  selectedNoteExamen,
  onChange,
}: AddEvaluationGradeEntryFinalGradeCellProps) {
  return (
    <div className="d-flex justify-content-center">
      {percentage && !selectedNoteExamen ? (
        addtTextWithColor(String(percentage))
      ) : (
        <input
          className="text-center border-input"
          type="text"
          disabled={!selectedNoteExamen}
          value={percentage}
          onChange={(e) => {
            let value = formattertNote(e.target.value);

            if (value || !e.target.value) {
              onChange(value);
            }
          }}
          style={{
            maxWidth: '89px',
            height: '36px',
          }}
        />
      )}
    </div>
  );
}
