import { SidenavProps } from '@octano/global-ui';

// TODO: Los textos de los menús deberían venir desde las traducciones
export const SIDENAV_LINKS: SidenavProps['links'] = [
  {
    path: '/classes/start',
    name: 'Iniciar clases',
    icon: 'chalkboard',
  },
  {
    path: '/classes/history',
    name: 'Recuperar o registra una clase',
    icon: 'classes_history',
  },
  {
    path: '/classes/untracked-attendance',
    name: 'Clases dictadas sin registro',
    icon: 'class_untracked_attendance',
  },
  {
    path: '/classes/tracking',
    name: 'Seguimiento de asistencia',
    icon: 'attendance_tracking',
  },
  {
    path: '/grade-entry/list',
    name: 'Ingreso de calificaciones',
    icon: 'grade_entry',
  },
  {
    path: '/reports',
    name: 'Histórico de clases',
    icon: 'certificates_requests',
  },
  {
    path: '/period-end',
    name: 'Cierre del período',
    icon: 'schedule_upload',
  },
];
