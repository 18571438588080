import { Button, ColumnTable, Table, useMobile } from '@octano/global-ui';
import dayjs from 'dayjs';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import ClassTypeLegend from '../../../components/ClassTypeLegend/ClassTypeLegend';
import NoResultsMsg from '../../../components/NoResultsMsg/NoResultsMsg';
import useCurrentTimeSubscription from '../../../hooks/useCurrentTimeSubscription';
import useServerSettings from '../../../hooks/useServerSettings';
import { useUserState } from '../../../hooks/useUserState';
import { ActivityName } from '../../../types/Class';
import showModalsectionClosedAt from '../../../utils/showModalSectionClosedAt';
import { SelectedClass } from './PastClassesSearch';

interface Props {
  results: SelectedClass[];
  currentPage: number;
  totalPages: number;
  totalItems: number;
  resultsPerPage: number;
  isLoadingResults: boolean;
  onPageChange: (page: number) => void;
  onClassRegistration: (classData: SelectedClass) => void;
  onClassRecovery: (classData: SelectedClass) => void;
}

export default function FutureClassesTable({
  results,
  currentPage,
  totalPages,
  totalItems,
  resultsPerPage,
  isLoadingResults,
  onPageChange,
  onClassRegistration,
  onClassRecovery,
}: Props) {
  const { t } = useTranslation();

  const isMobile = useMobile();
  const { offset } = useServerSettings();
  const currentTime = useCurrentTimeSubscription({
    offset,
    refreshEvery: 15000,
  });

  const { userData } = useUserState();

  const canRegisterOrRecover = useCallback(
    (schedule: string, date: string) => {
      const endTimeStr = schedule.split(' - ')[1];
      const [hours, minutes] = endTimeStr.split(':').map((n) => Number(n));

      const classEndTime = dayjs(date)
        .hour(hours)
        .minute(minutes)
        .second(0)
        .utcOffset(offset, true);

      return currentTime.isAfter(classEndTime);
    },
    [currentTime, offset],
  );

  const TABLE_COLUMNS: ColumnTable<SelectedClass>[] = useMemo(() => {
    if (isMobile) {
      return [
        {
          headerText: t(`common.terms.date`),
          columnName: 'date',
        },
        {
          headerText: t(`common.terms.modules`),
          columnName: 'modules',
        },
        {
          headerText: '',
          columnName: 'action',
          width: '180px',
          cellFormat: (data) => {
            const { schedule, date } = data.row;
            const disableBtn = !canRegisterOrRecover(schedule, date);

            return (
              <div className="d-flex">
                <Button
                  text="RECUPERAR"
                  outlined
                  onClick={() => onClassRecovery(data.row)}
                  size="sm"
                  className="mr-3"
                  disabled={disableBtn}
                />
                <Button
                  text="REGISTRAR"
                  outlined
                  onClick={() => onClassRegistration(data.row)}
                  size="sm"
                  disabled={disableBtn}
                />
              </div>
            );
          },
        },
      ];
    } else {
      return [
        {
          headerText: t(`common.terms.date`),
          columnName: 'date',
        },
        {
          headerText: t(`common.terms.modules`),
          columnName: 'modules',
        },
        {
          headerText: t(`common.terms.schedule`),
          columnName: 'schedule',
        },
        {
          headerText: t(`common.terms.activity`),
          columnName: 'activity',
          cellFormat: (data) => (
            <ClassTypeLegend activityName={data.row.activity as ActivityName} />
          ),
        },
        {
          headerText: t(`common.terms.responsability`),
          columnName: '123',
          cellFormat: (data) => {
            const isHeadTeacher = data.row.professors.some(
              (p: any) => p.account.teacherId === userData?.teacherId,
            );
            return isHeadTeacher
              ? t(`common.terms.headTeacher`)
              : t(`common.terms.classResponsible`);
          },
        },
        {
          headerText: '',
          columnName: 'action',
          width: '180px',
          cellFormat: (data) => {
            const { schedule, date } = data.row;
            const disableBtn = !canRegisterOrRecover(schedule, date);

            const isHeadTeacher = data.row.professors.some(
              (p: any) => p.account.teacherId === userData?.teacherId,
            );

            return (
              <div className="d-flex">
                <Button
                  text={t(`common.btnRecover`)}
                  outlined
                  onClick={() => {
                    if (data.row.sectionClosedAt) {
                      showModalsectionClosedAt(t);
                    } else {
                      onClassRecovery(data.row);
                    }
                  }}
                  size="sm"
                  className="mr-3"
                  disabled={disableBtn || !isHeadTeacher}
                />
                <Button
                  text={t(`common.btnRegister`)}
                  outlined
                  onClick={() => {
                    if (data.row.sectionClosedAt) {
                      showModalsectionClosedAt(t);
                    } else {
                      onClassRegistration(data.row);
                    }
                  }}
                  size="sm"
                  disabled={disableBtn}
                />
              </div>
            );
          },
        },
      ];
    }
  }, [
    isMobile,
    t,
    userData,
    canRegisterOrRecover,
    onClassRecovery,
    onClassRegistration,
  ]);

  return (
    <div className="text-center">
      <Table
        noResultsText={<NoResultsMsg />}
        columns={TABLE_COLUMNS}
        data={results}
        pagination={
          totalPages > 1
            ? {
                totalItems,
                totalPages,
                currentPage: currentPage + 1,
                itemsPerPage: resultsPerPage,
                onChangePage: (page) => onPageChange(page - 1),
              }
            : undefined
        }
        isLoadingResults={isLoadingResults}
        loadingView={{
          title: t(`classesHistory.loadingTitles.title`),
          subtitle: t(`classesHistory.loadingTitles.subtitle`),
        }}
      />
    </div>
  );
}
