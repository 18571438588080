import { showDialogInfo } from '@octano/global-ui';

export default function showModalsectionClosedAt(t: any) {
  showDialogInfo({
    icon: {
      name: 'warning',
      color: 'primary',
    },
    title: t('sectionClosedModal.title'),
    subtitle: t('sectionClosedModal.descriptions.closedAt'),
    btnConfirm: {
      text: t('common.btnGotIt'),
      onConfirm: console.log,
    },
  });
}
