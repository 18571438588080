import { SectionStudents } from '../../types/Class';
import { SearchParamsPagination } from '../../types/Filters';
import { SectionResponse } from '../../types/Section';
import { PaginationRequestType } from '../../types/paginationRequestType';
import { SECTIONS, TEACHER_PORTAL_SECTIONS } from '../endpoints';
import request from '../request';

/**
 * Obtiene todas las secciones para las cuales dicta clase un profesor
 */
export const getSectionsByAccountId = ({
  period = true,
  page = 0,
  itemsPerPage = 10,
  periodId,
  schoolId,
  campusId,
  searchSection,
  sectionStatus,
}: {
  period?: boolean;
} & SearchParamsPagination) => {
  const url = TEACHER_PORTAL_SECTIONS.GET_ALL_SECTION;
  const params = {
    items_per_page: itemsPerPage,
    page: page,
    period,
    periodId,
    schoolId,
    campusId,
    searchSection,
    sectionStatus,
  };
  return request<PaginationRequestType<SectionResponse>>(url, { params });
};

/**
 * Obtiene la lista de estudiantes inscritos en esa seccion
 * @param id: Id de la seccion
 * @returns
 */
export function getStudentsList(id: number) {
  const url = `${SECTIONS.BASE}/${id}${SECTIONS.STUDENTS}`;
  return request<SectionStudents>(url);
}

/**
 * Obtiene el estatus de la seccion
 * @param id: Id de la seccion
 * @returns
 */
export function getSectionStatus(id: number) {
  const url = TEACHER_PORTAL_SECTIONS.GET_STATUS(id);
  return request<boolean>(url);
}
