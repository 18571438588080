import { Button, DateInput, Modal, useMobile } from '@octano/global-ui';
import dayjs from 'dayjs';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

import { useValidations } from '../../../hooks/useValidations';

const getYesterdaysDate = () => dayjs().subtract(1, 'd').toDate();

const DEFAULT_VALUES = {
  initDate: dayjs(getYesterdaysDate()).date(1).toDate(),
  endDate: getYesterdaysDate(),
};

type FormValues = typeof DEFAULT_VALUES;
type DateStrings = { initDate: string; endDate: string };

interface Props {
  isOpen: boolean;
  onConfirm?: (dateValues: DateStrings) => void;
  onCancel?: () => void;
}

export default function SelectDatesModal({
  isOpen,
  onConfirm = () => null,
  onCancel = () => null,
}: Props) {
  const { t } = useTranslation();
  const prefix = 'reports.classHistory.datesConfig';

  const isMobile = useMobile();

  const { msgValidations } = useValidations();
  const { control, watch, handleSubmit } = useForm({
    defaultValues: DEFAULT_VALUES,
    shouldUnregister: true,
  });

  const { initDate } = watch();

  const handleConfirm = async (formValues: FormValues) => {
    const { initDate: init, endDate: end } = formValues;

    onConfirm({
      initDate: dayjs(init).format('YYYY-MM-DD'),
      endDate: dayjs(end).format('YYYY-MM-DD'),
    });
  };

  return (
    <Modal isOpen={isOpen} toggle={() => null}>
      <form onSubmit={handleSubmit(handleConfirm)}>
        <h1 className="text-dark text-center fw-600 fs-20">
          {t(`${prefix}.title`)}
        </h1>
        <p className=" text-center fs-16 lh-30 mt-4 mb-5">
          {t(`${prefix}.subtitle`)}
        </p>
        <Row>
          <Col lg="6" md="6" sm="12">
            <DateInput
              name="initDate"
              label={t(`common.forms.dateFrom`)}
              control={control}
              maxDate={getYesterdaysDate()}
              rules={{
                required: msgValidations.required,
              }}
            />
          </Col>
          <Col lg="6" md="6" sm="12">
            <DateInput
              name="endDate"
              label={t(`common.forms.dateTo`)}
              control={control}
              minDate={initDate}
              maxDate={DEFAULT_VALUES.endDate}
              rules={{
                required: msgValidations.required,
              }}
            />
          </Col>
        </Row>
        <div
          className="d-flex flex-wrap justify-content-between mt-5"
          style={{ gap: '1rem' }}
        >
          <Button
            text={t(`common.btnCancel`)}
            outlined
            style={{ width: isMobile ? '100%' : 197 }}
            onClick={onCancel}
          />
          <Button
            type="submit"
            text={t(`common.btnDownload`)}
            style={{ width: isMobile ? '100%' : 197 }}
          />
        </div>
      </form>
    </Modal>
  );
}
