import {
  CellFormatOptions,
  ColumnTable,
  Icon,
  Table,
  useMobile,
} from '@octano/global-ui';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getAllReports } from '../../api/requests/reports';
import { Report } from '../../types/Reports';
import ReportActionsCell from './parts/ReportActionsCell/ReportActionCell';

const ITEMS_PER_PAGE = 6;

const filterColumnsByMobile = (
  onMobile: boolean,
  columns: ColumnTable<Report>[],
) => {
  return columns.filter((column) => {
    if (!onMobile) return true;
    return column.columnName !== 'description';
  });
};

export default function Reports() {
  const { t } = useTranslation();
  const prefix = 'reports';

  const isMobile = useMobile();

  const [reports, setReports] = useState<Report[]>([]);
  const [isLoadingReports, setIsLoadingReports] = useState(false);
  const [error, setError] = useState(false);

  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [totalReports, setTotalReports] = useState(0);

  const TABLE_COLUMNS: ColumnTable[] = [
    {
      columnName: 'name',
      headerText: t(`common.terms.report`),
      tdClassName: 'text-center',
      thClassName: 'text-center',
    },
    {
      columnName: 'description',
      headerText: t(`common.terms.description`),
      tdClassName: 'text-center',
      thClassName: 'text-center',
    },
    {
      columnName: 'action',
      headerText: t(`common.terms.action`),
      tdClassName: 'text-center',
      thClassName: 'text-center',
      cellFormat: (data: CellFormatOptions<Report>) => (
        <ReportActionsCell report={data.row} />
      ),
    },
  ];

  useEffect(() => {
    const fetchReportsList = async () => {
      setIsLoadingReports(true);
      const { data, error: fetchError } = await getAllReports();

      if (fetchError) return setError(true);

      setTotalPages(data?.total_pages || 0);
      setTotalReports(data?.total || 0);

      const orderedReports = data?.data.sort((rp1, rp2) =>
        rp1.name > rp2.name ? 1 : -1,
      );
      setReports(orderedReports || []);

      setIsLoadingReports(false);
    };

    fetchReportsList();
  }, []);

  return (
    <div className="m-3 pt-2 pb-4 px-3 bg-white rounded">
      <h2 className="text-primary fs-20 fw-700 mt-4">
        {t(`${prefix}.pageTitle`)}
      </h2>
      <p className="fs-16 mt-3 mb-4">{t(`${prefix}.pageDescription`)}</p>
      {error ? (
        <div className="d-flex justify-content-center align-items-center text-center">
          <div className="p-5" style={{ maxWidth: 500 }}>
            <Icon name="warning" size="50px" />
            <h1 className="fs-20 mt-4 text-dark">
              {t(`${prefix}.errors.reportsListError.title`)}
            </h1>
            <p className="fs-16 mt-3">
              {t(`${prefix}.errors.reportsListError.subtitle`)}
            </p>
          </div>
        </div>
      ) : (
        <Table
          data={reports}
          columns={filterColumnsByMobile(isMobile, TABLE_COLUMNS)}
          pagination={{
            currentPage: currentPage + 1,
            itemsPerPage: ITEMS_PER_PAGE,
            totalItems: totalReports,
            totalPages: totalPages,
            onChangePage: (page) => setCurrentPage(page),
          }}
          isLoadingResults={isLoadingReports}
          loadingView={{
            title: t(`${prefix}.loading.reportsList.title`),
            subtitle: t(`${prefix}.loading.reportsList.subtitle`),
          }}
          striped
        />
      )}
    </div>
  );
}
