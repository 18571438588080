import dayjs from 'dayjs';

import {
  ActivityName,
  Class,
  ClassResponseData,
  ClassType,
} from '../types/Class';

/**
 * Se puede utilizar para mapear un nombre de registro de bd al label de tipos de clase
 * @param name: El nombre de la actividad a mapear a un tipo de clase
 * @returns un tipo de clase basado en ClassType
 */
export function mapActivityNameToClassType(name: ActivityName): ClassType {
  return (
    {
      Catedra: ClassType.COURSE,
      Laboratorio: ClassType.LAB,
      Terreno: ClassType.FIELD,
      Taller: ClassType.WORKSHOP,
      Other: ClassType.OTHER,
      Tésis: ClassType.THESIS,
      Ayudantía: ClassType.ASSISTANTSHIP,
      Práctica: ClassType.PRACTICE,
    }[name] || ClassType.COURSE
  );
}

/**
 * Convierte la respuesta de clases del dia en un modelo mas manejable del lado del front
 * @param classResData: Registro de clase proveniente del backend
 * @returns una instancia de Clase resumida
 */
export function selectClassData(classResData: ClassResponseData): Class {
  const { id, date, status, sectionSchedule, teacher, account } = classResData;
  const {
    startModule,
    endModule,
    section,
    activity,
    classroom,
    day,
    responsibles,
  } = sectionSchedule;

  const type = mapActivityNameToClassType(activity.name as ActivityName);

  const startTime = dayjs(`${date} ${startModule.startTime}`).format('HH:mm');
  const endTime = dayjs(`${date} ${endModule.endTime}`).format('HH:mm');

  return {
    id,
    status,
    date,
    day,
    type,
    teacher,
    typeLabel: activity.name,
    name: section.course.name,
    modules: `${startModule.shortening} - ${endModule.shortening}`,
    section: section.name,
    classroom: classroom.name,
    schedule: `${startTime} - ${endTime}`,
    responsibles: responsibles.map((r) => r.account.fullName),
    professors: section.professors.map((p) => p.account.fullName),
    accountId: account?.id,
    sectionClosedAt: section.closedAt,
  };
}
