import { Button } from '@octano/global-ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useUserState } from '../../../hooks/useUserState';
import { Class, ClassStatus } from '../../../types/Class';
import showModalsectionClosedAt from '../../../utils/showModalSectionClosedAt';

interface Props {
  classData: Class;
  onStartClick?: (classData: Class) => any;
  onModifyClick?: (classData: Class) => any;
}

export default function ClassCard({
  classData,
  onStartClick = () => {},
  onModifyClick = () => {},
}: Props) {
  const { t } = useTranslation();
  const { userData } = useUserState();

  const handleBtnClick = () => {
    if (classData.sectionClosedAt) {
      showModalsectionClosedAt(t);
    } else if (inProgress) {
      onModifyClick(classData);
    } else {
      onStartClick(classData);
    }
  };

  const inProgress = classData.status === ClassStatus.INIT;
  const canModify = useMemo(() => classData.accountId === userData?.id, [
    classData,
    userData?.id,
  ]);

  return (
    <div className="card class-card mr-5 px-4 py-3 ">
      <div className="data-grid">
        <div>
          <p className="fs-16 fw-700 text-dark mb-0">
            {t(`common.terms.course`)}
          </p>
          <p className="text-medium" title={classData.name}>
            {classData.name}
          </p>
        </div>
        <div>
          <p className="fs-16 fw-700 text-dark mb-0">
            {t(`common.terms.schedule`)}
          </p>
          <p
            className="text-medium text-uppercase"
            title={`${classData.day} ${classData.schedule}`}
          >
            {classData.day} {classData.schedule}
          </p>
        </div>
        <div>
          <p className="fs-16 fw-700 text-dark mb-0">
            {t(`common.terms.room`)}
          </p>
          <p className="text-medium">{classData.classroom}</p>
        </div>
        <div>
          <p className="fs-16 fw-700 text-dark mb-0">
            {t(`common.terms.section`)}
          </p>
          <p className="text-medium" title={classData.section}>
            {classData.section}
          </p>
        </div>
        <div>
          <p className="fs-16 fw-700 text-dark mb-0">
            {t(`common.terms.module`)}
          </p>
          <p className="text-medium text-uppercase" title={classData.modules}>
            {classData.modules}
          </p>
        </div>
        <div>
          <p className="fs-16 fw-700 text-dark mb-0">
            {t(`common.terms.type`)}
          </p>
          <div className={`box-class-type-legend type-${classData.type}`}>
            <p className="text-medium mb-0" title={classData.typeLabel}>
              {classData.typeLabel}
            </p>
          </div>
        </div>
        <div>
          <p className="fs-16 fw-700 text-dark mb-0">
            {t(`common.terms.headTeacher`)}
          </p>
          <p className="text-medium" title={classData.professors.join(', ')}>
            {classData.professors.join(', ')}
          </p>
        </div>
        <div>
          <p className="fs-16 fw-700 text-dark mb-0">
            {t(`common.terms.classResponsible`)}
          </p>
          <p className="text-medium" title={classData.responsibles.join(', ')}>
            {classData.responsibles.join(', ')}
          </p>
        </div>
      </div>
      <div className={`btn-container ${inProgress && 'inProgress'}`}>
        <div>
          {inProgress && (
            <div className="status-label border border-primary rounded d-flex align-items-center justify-content-center text-primary fw-700">
              {t(`startClasses.classInitiated`)}
            </div>
          )}
        </div>
        <Button
          outlined={inProgress}
          text={
            inProgress ? t(`common.btnModifyClass`) : t(`common.btnStartClass`)
          }
          onClick={handleBtnClick}
          size="sm"
          className={`btn ${inProgress ? '' : 'can-grow'}`}
          fullwidth={false}
          disabled={inProgress && !canModify}
        />
      </div>
    </div>
  );
}
