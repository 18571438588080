import { Icon } from '@octano/global-ui';

interface Props {
  boldTitle: string;
  title?: string;
  titleNextLine?: string;
}

export default function TrackingTableErrorMsg({
  boldTitle,
  title = '',
  titleNextLine = '',
}: Props) {
  return (
    <div className="d-flex align-items-center justify-content-center">
      <div
        className="d-flex flex-column align-items-center text-center"
        style={{ maxWidth: 346, marginTop: 70, marginBottom: 65 }}
      >
        <Icon name="warning" color="primary" size="61px" />
        <p className="text-medium fw-700 mt-4 mb-3 fs-18">{boldTitle}</p>
        <p className="text-light lh-30 mb-0 fs-16">{title}</p>
        <p className="text-light fs-16">{titleNextLine}</p>
      </div>
    </div>
  );
}
