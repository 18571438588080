import {
  downloadReport,
  uploadReportData,
} from '../../../../api/requests/reports';
import { Report } from '../../../../types/Reports';

type BaseHandlerParams = {
  onSuccess?: (data: any) => void;
  onError?: (error: any) => void;
};

type ReportDataUploadHandlerParams = BaseHandlerParams & {
  afterUpload?: ({ data, error }: any) => void;
  beforeUpload?: () => void;
};

type ReportDownloadHandlerParams = BaseHandlerParams & {
  afterDownload?: ({ data, error }: any) => void;
  beforeDownload?: () => void;
};

export const createReportDataUploadHandler = ({
  beforeUpload = () => null,
  afterUpload = () => null,
  onError = () => null,
  onSuccess = () => null,
}: ReportDataUploadHandlerParams) => {
  return async (uploadUrl: Report['downloadUrl'], data?: any) => {
    beforeUpload();

    const res = await uploadReportData(uploadUrl, data);

    if (res.error) {
      onError(res.error);
    } else {
      onSuccess(res.data);
    }

    afterUpload({ data: res.data, error: res.error });
  };
};

export const createReportDownloadHandler = ({
  beforeDownload = () => null,
  afterDownload = () => null,
  onError = () => null,
  onSuccess = () => null,
}: ReportDownloadHandlerParams) => {
  return async (downloadUrl: Report['downloadUrl'], params?: any) => {
    beforeDownload();

    const res = await downloadReport(downloadUrl, params);

    if (res.error) {
      onError(res.error);
    } else {
      onSuccess(res.data);
    }

    afterDownload({ data: res.data, error: res.error });
  };
};
