import { Icon } from '@octano/global-ui';

export default function EmptyStateMsg({
  msg,
  date,
}: {
  msg: string;
  date: string;
}) {
  return (
    <div className="empty-state-msg">
      <div className="icon-container">
        <Icon name="check" color="white" />
      </div>
      <p className="message">
        {msg}
        <br />
        {date}
      </p>
    </div>
  );
}
