import { OutlinedSelectOptionType } from '@octano/global-ui';

export enum TeacherSectionStatus {
  SectionClosed = 'Closed',
  SectionOpen = 'Open',
  SectionAll = 'All',
}

export type SearchParams = {
  periodId?: number;
  schoolId?: number;
  campusId?: number;
  searchSection?: string;
  hasFilters?: boolean;
  sectionStatus?: TeacherSectionStatus;
};

export type SearchParamsPagination = SearchParams & {
  page?: number;
  itemsPerPage?: number;
};

export type SearchParamsWithoutPeriod = Omit<
  SearchParamsPagination,
  'periodId'
>;

export type SelectOptionWithDefaultValue =
  | OutlinedSelectOptionType
  | {
      label: string;
      value: null;
    };
