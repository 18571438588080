import { useEffect, useState } from 'react';

import { getTimeModules } from '../api/requests/timeModules';
import { TimeModule } from '../types/TimeModule';

export default function useTimeModules() {
  const [timeModules, setTimeModules] = useState<TimeModule[]>([]);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const requestTimeModules = async () => {
      setIsLoading(true);
      const res = await getTimeModules();
      setTimeModules(res.data ?? []);

      setIsLoading(false);
    };

    requestTimeModules();
  }, []);

  return {
    isLoading,
    timeModules,
  };
}
