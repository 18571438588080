import { AttendanceTableResponse } from '../../types/AttendanceTracking';
import { ClassAttendanceArrayObject } from '../../types/Class';
import { AttendantRecord } from '../../types/Class';
import { ATTENDANCE, SECTIONS } from '../endpoints';
import request from '../request';

/**
 * Obtiene la lista de asistencia de una clase para un modulo especifico
 */
export function getClassAttendance({
  moduleId,
  classId,
  itemsPerPage = Number.MAX_SAFE_INTEGER,
  page = 0,
}: {
  moduleId: number;
  classId: number;
  itemsPerPage?: number;
  page?: number;
}) {
  const url = `${ATTENDANCE.BASE}/${moduleId}/${classId}`;
  const params = {
    items_per_page: itemsPerPage,
    page,
  };
  return request<ClassAttendanceArrayObject>(url, { params });
}

/**
 * Crea y actualiza registros de asistencia
 */
export function saveAttendants(attendants: AttendantRecord[]) {
  const url = `${ATTENDANCE.BASE}`;
  const method = 'POST';
  const data = {
    assistance: attendants,
  };

  return request<any>(url, { method, data });
}

/**
 * Obtiene la tabla de seguimiento de asistencia
 */
export function getTrackingTable({ sectionId }: { sectionId: number }) {
  const url = `${ATTENDANCE.BASE}${SECTIONS.BASE}/${sectionId}${ATTENDANCE.BASE}`;
  return request<{ data: AttendanceTableResponse }>(url);
}
