import { addToast } from '@octano/global-ui';
import dayjs from 'dayjs';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import {
  reScheduleClass,
  searchClasses,
  updateClassStatus,
} from '../../api/requests/classes';
import useServerSettings from '../../hooks/useServerSettings';
import useTimeModules from '../../hooks/useTimeModules';
import { ClassStatus } from '../../types/Class';
import { ClassRecoveryModal, PastClassesSearch } from './parts';
import { SubmitData as ModalSubmitData } from './parts/ClassRecoveryModal';
import ConfirmRecoveryDialog from './parts/ConfirmRecoveryDialog';

export default function ClassesHistory() {
  const history = useHistory();
  const { t } = useTranslation();

  const [classToRecover, setClassToRecover] = useState<any>();
  const [recoveryFormData, setRecoveryFormData] = useState<ModalSubmitData>();

  const { timeModules } = useTimeModules();
  const { offset: offsetUTC } = useServerSettings();
  const searchControls = useRef<any>();

  const isCurrentlyRunning = (
    startTime: string,
    endTime: string,
    date: string,
  ) => {
    const [startHours, startMinutes] = startTime
      .split(':')
      .map((n) => Number(n));
    const [endHours, endMinutes] = endTime.split(':').map((n) => Number(n));

    const startDateTime = dayjs(date)
      .utc()
      .utcOffset(offsetUTC)
      .hour(startHours)
      .minute(startMinutes);
    const endDateTime = dayjs(date)
      .utc()
      .utcOffset(offsetUTC)
      .hour(endHours)
      .minute(endMinutes);

    const currentDateTime = dayjs().utc().utcOffset(offsetUTC);

    return currentDateTime.isBetween(startDateTime, endDateTime);
  };

  const hasConflictWithAnotherClass = async (classData: ModalSubmitData) => {
    const date = dayjs(classData.date).format('YYYY-MM-DD');

    const res = await searchClasses({
      sectionId: classToRecover.sectionId,
      page: 0,
      itemsPerPage: Number.MAX_SAFE_INTEGER,
      initialDate: date,
      endDate: date,
    });

    const classesList = res.data?.data
      .filter((cd: any) => {
        return (
          cd.status !== ClassStatus.CLOSED &&
          cd.status !== ClassStatus.CANCELLED
        );
      })
      .map((cd: any) => {
        const { sectionSchedule } = cd;
        const { startModule, endModule } = sectionSchedule;
        return { startTime: startModule.startTime, endTime: endModule.endTime };
      });

    const {
      startTime: newStartTime,
      endTime: newEndTime,
    } = getClassStartAndEndTime(classData);
    const [newStartHours, newStartMinutes] = newStartTime.split(':');
    const [newEndHours, newEndMinutes] = newEndTime.split(':');
    const newStartDateTime = dayjs()
      .utc()
      .utcOffset(offsetUTC)
      .hour(Number(newStartHours))
      .minute(Number(newStartMinutes))
      .second(0);
    const newEndDateTime = dayjs()
      .utc()
      .utcOffset(offsetUTC)
      .hour(Number(newEndHours))
      .minute(Number(newEndMinutes))
      .second(0);

    return classesList?.reduce((_: boolean, listItem: any) => {
      const { startTime: itemStartTime, endTime: itemEndTime } = listItem;
      const [itemStartHours, itemStartMinutes] = itemStartTime.split(':');
      const [itemEndHours, itemEndMinutes] = itemEndTime.split(':');

      const itemStartDateTime = dayjs()
        .utc()
        .utcOffset(offsetUTC)
        .hour(itemStartHours)
        .minute(itemStartMinutes)
        .second(0);
      const itemEndDateTime = dayjs()
        .utc()
        .utcOffset(offsetUTC)
        .hour(itemEndHours)
        .minute(itemEndMinutes)
        .second(0);

      return (
        (newStartDateTime.isSameOrBefore(itemStartDateTime) &&
          itemStartDateTime.isSameOrBefore(newEndDateTime)) ||
        (newStartDateTime.isSameOrBefore(itemEndDateTime) &&
          itemEndDateTime.isSameOrBefore(newEndDateTime)) ||
        (itemStartDateTime.isBefore(newStartDateTime) &&
          newEndDateTime.isBefore(itemEndDateTime))
      );
    }, false);
  };

  const getClassStartAndEndTime = (classData: ModalSubmitData) => {
    const startTime =
      timeModules.find((tm) => tm.id === classData.initModule.id)?.startTime ??
      '00:00';
    const endTime =
      timeModules.find((tm) => tm.id === classData.endModule.id)?.endTime ??
      '00:00';

    return { startTime, endTime };
  };

  const recoverClass = async (formData: ModalSubmitData, force?: boolean) => {
    const hasScheduleConflicts = await hasConflictWithAnotherClass(formData);

    if (!force && hasScheduleConflicts) {
      setRecoveryFormData(formData);
      return;
    }

    setRecoveryFormData(undefined);

    const res = await reScheduleClass({
      dateRecovery: dayjs(formData.date).format('YYYY-MM-DD'),
      startModuleId: formData.initModule.id,
      endModuleId: formData.endModule.id,
      lessonsId: classToRecover.id,
      sectionId: classToRecover.sectionId,
    });

    const recoveredClassId = res.data?.data.id;

    if (res.error) {
      addToast({
        icon: 'information',
        color: 'danger',
        text: t(`classesHistory.errors.failedRecoveringClass`),
      });
    } else {
      addToast({
        icon: 'check',
        color: 'success',
        text: t(`classesHistory.successMsgs.classScheduled`),
      });

      const { startTime, endTime } = getClassStartAndEndTime(formData);

      const isRunning = isCurrentlyRunning(
        startTime || '',
        endTime || '',
        formData.date,
      );

      if (isRunning) {
        await updateClassStatus({
          status: ClassStatus.INIT,
          id: recoveredClassId as number,
        });
        history.push(`/classes/${recoveredClassId}/attendance`);
      } else {
        setClassToRecover(undefined);

        /* Actualizamos la tabla */
        searchControls.current?.repeatSearch();
      }
    }
  };

  const registerAttendants = async (classData: any) => {
    const { id } = classData;

    const { error } = await updateClassStatus({
      status: ClassStatus.INIT,
      id,
    });

    if (error) {
      addToast({
        icon: 'information',
        color: 'danger',
        text: t(`classesHistory.errors.failedRegisteringClass`),
      });
    } else {
      history.push(`/classes/${id}/attendance`);
    }
  };

  return (
    <>
      <ConfirmRecoveryDialog
        isOpen={!!recoveryFormData}
        onConfirm={() =>
          recoverClass(recoveryFormData as ModalSubmitData, true)
        }
        onCancel={() => setRecoveryFormData(undefined)}
      />
      <ClassRecoveryModal
        isOpen={!!classToRecover}
        onConfirm={recoverClass}
        onCancel={() => setClassToRecover(undefined)}
        courseName={classToRecover?.courseName || ''}
      />
      <div className="m-3 py-4 px-3 bg-white rounded">
        <PastClassesSearch
          onClassRegistration={registerAttendants}
          onClassRecovery={(classData: any) => setClassToRecover(classData)}
          ref={searchControls}
        />
      </div>
    </>
  );
}
