import { ActivityName } from '../../types/Class';
import { mapActivityNameToClassType } from '../../utils/classes';

interface Props {
  activityName: ActivityName;
}

export default function ClassTypeLegend({ activityName }: Props) {
  return (
    <div
      className={`box-class-type-legend type-${mapActivityNameToClassType(
        activityName,
      )}`}
    >
      <p className="text-medium mb-0">{activityName}</p>
    </div>
  );
}
