import { Button, Table, addToast } from '@octano/global-ui';
import { CellFormatOptions } from '@octano/global-ui/dist/components/Table/types/TableTypes';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getSectionsByAccountId } from '../../../api/requests/sections';
import { PeriodsLoader } from '../../../components/LoaderContexts/PeriodsLoader';
import { SearchControls } from '../../../components/SearchControls/SearchControls';
import SectionClosedModal from '../../../components/SectionClosedModal/SectionClosedModal';
import { useUserState } from '../../../hooks/useUserState';
import { SearchParams } from '../../../types/Filters';
import { SectionResponse } from '../../../types/Section';
import NoSectionsMsg from './NoSectionsMsg';

export type SectionRow = {
  id: number;
  shortening: string;
  sectionName: string;
  className: string;
  professors: { id: number }[];
  closedAt: string | null;
  course: SectionResponse['course'];
};

interface Props {
  onSectionSelection?: (row: SectionRow) => void;
}

interface HandleSearch {
  searchParams?: SearchParams;
  page: number;
}

const ITEMS_PER_PAGE_DEFAULT = 10;

export default function SectionsTable({
  onSectionSelection = () => null,
}: Props) {
  const { t } = useTranslation();
  const { userData } = useUserState();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [openSectionClosedModal, setOpenSectionClosedModal] = useState<boolean>(
    false,
  );

  const [tableData, setTableData] = useState<SectionRow[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [filters, setFilters] = useState<SearchParams>({});

  const handleSearch = useCallback(
    async ({ searchParams, page = 0 }: HandleSearch) => {
      setIsLoading(true);

      const { data, error } = await getSectionsByAccountId({
        page,
        itemsPerPage: ITEMS_PER_PAGE_DEFAULT,
        periodId: searchParams?.periodId,
        schoolId: searchParams?.schoolId,
        campusId: searchParams?.campusId,
        searchSection: searchParams?.searchSection,
        sectionStatus: searchParams?.sectionStatus,
        // hasFilters: searchParams?.hasFilters,
      });

      if (error) {
        addToast({
          color: 'danger',
          icon: 'information',
          text: t(`attendanceTracking.errors.retrievingSectionsList`),
        });
      }

      if (data) {
        const mappedRows = data.data.map((sectionRes: SectionResponse) => ({
          id: sectionRes.id,
          periodName: sectionRes.period?.name,
          schoolName: sectionRes.course?.school?.name,
          campusName: sectionRes?.campus?.name,
          shortening: sectionRes.course.shortening,
          sectionName: sectionRes.name,
          className: sectionRes.course.name,
          professors: sectionRes.professors,
          closedAt: sectionRes.closedAt,
          course: sectionRes.course,
        }));

        setTableData(mappedRows ?? []);
        setTotalItems(data.total);
        setTotalPages(data.total_pages);
      }

      setIsLoading(false);
    },
    [t],
  );

  const handleClear = async () => {
    setTableData([]);
    setFilters({});
    setCurrentPage(0);
  };

  useEffect(() => {
    handleSearch({ searchParams: filters, page: currentPage });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleSearch, currentPage]);

  const TABLE_COLUMNS = useMemo(() => {
    return [
      {
        headerText: t(`common.terms.period`),
        columnName: 'periodName',
        thClassName: 'text-center',
      },
      {
        headerText: t(`common.terms.schoolName`),
        columnName: 'schoolName',
      },
      {
        headerText: t(`common.terms.campusName`),
        columnName: 'campusName',
      },
      {
        headerText: t(`common.terms.shortening`),
        columnName: 'shortening',
      },
      {
        headerText: t(`common.terms.courseName`),
        columnName: 'className',
      },
      {
        headerText: t(`common.terms.section`),
        columnName: 'sectionName',
      },
      {
        headerText: '',
        columnName: 'action',
        cellFormat: (data: CellFormatOptions<SectionRow>) => {
          const isHeadTeacher = data.row.professors.some(
            (p) => p.id === userData?.teacherId,
          );

          return (
            <div className="d-flex justify-content-end">
              <Button
                text={t(`attendanceTracking.checkAttendanceBtn`)}
                color="primary"
                size="sm"
                outlined
                onClick={() => {
                  onSectionSelection(data.row);
                }}
                disabled={!isHeadTeacher}
              />
            </div>
          );
        },
      },
    ];
  }, [onSectionSelection, t, userData?.teacherId]);

  return (
    <PeriodsLoader>
      {({ data }) => (
        <>
          <SectionClosedModal
            isOpen={openSectionClosedModal}
            onConfirm={() => setOpenSectionClosedModal(false)}
            descriptionType="attendanceTracking"
          />

          <SearchControls
            periods={data.periods}
            onSearch={handleSearch}
            onClear={handleClear}
            setFilters={setFilters}
          />

          <div className="text-center">
            <Table
              columns={TABLE_COLUMNS}
              data={tableData}
              isLoadingResults={isLoading}
              pagination={{
                currentPage: currentPage + 1,
                totalItems,
                totalPages,
                itemsPerPage: ITEMS_PER_PAGE_DEFAULT,
                onChangePage: (page: number) => setCurrentPage(page - 1),
              }}
              loadingView={{
                title: t(`attendanceTracking.loadingTitles.title`),
                subtitle: t(`attendanceTracking.loadingTitles.subtitle`),
              }}
              noResultsText={<NoSectionsMsg />}
            />
          </div>
        </>
      )}
    </PeriodsLoader>
  );
}
