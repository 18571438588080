import { Box, useMobile } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';

export interface Props {
  studentName: string;
  date: string;
  moduleName: string;
}

export default function AttendanceInfoBox({
  studentName,
  date,
  moduleName,
}: Props) {
  const { t } = useTranslation();
  const isMobile = useMobile();

  return (
    <Box
      color="primary"
      variant="outlined"
      style={{ borderRadius: 4 }}
      className={`py-2 px-3 ${isMobile && 'mt-4 w-100'}`}
      body={
        <div className="d-flex justify-content-between">
          <Box
            color="primary"
            variant="outlined"
            className="p-0 border-0 w-50 text-uppercase"
            title={t(`common.terms.student`)}
            style={{ minWidth: isMobile ? 0 : 160 }}
            body={<p className="fs-14 mb-0 text-capitalize">{studentName}</p>}
          />
          <Box
            color="primary"
            variant="outlined"
            className="p-0 border-0 w-50 text-uppercase"
            title={t(`common.terms.date`)}
            style={{ minWidth: isMobile ? 0 : 130 }}
            body={<p className="fs-14 mb-0 text-capitalize">{date}</p>}
          />
          <Box
            color="primary"
            variant="outlined"
            className="p-0 border-0 w-50 text-uppercase"
            title={t(`common.terms.module`)}
            style={{ minWidth: isMobile ? 0 : 65 }}
            body={<p className="fs-14 mb-0 text-capitalize">{moduleName}</p>}
          />
        </div>
      }
    />
  );
}
