import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ClassResponseData } from '../types/Class';
import useTimeModules from './useTimeModules';

type TimeModuleOption = { label: string; value: number | string };

export default function useClassTimeModulesList(classData?: ClassResponseData) {
  const { timeModules, isLoading: isRequestingTimeModules } = useTimeModules();
  const [classModulesList, setClassModulesList] = useState<TimeModuleOption[]>(
    [],
  );
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    const fetchModules = () => {
      setIsLoading(true);
      let finalModules = [...timeModules];

      if (classData) {
        const startTime = classData.sectionSchedule.startModule.startTime;

        const endTime = classData.sectionSchedule.endModule.startTime;

        finalModules = timeModules.filter((tm) => {
          return startTime <= tm.startTime && tm.startTime <= endTime;
        });
      }

      const mappedModules = finalModules.map((module) => ({
        label: module.name,
        value: module.id,
      }));

      setClassModulesList([
        { label: t(`classAttendance.allModules`), value: 'all' },
        ...mappedModules,
      ]);
      setIsLoading(false);
    };

    if (classData && !isRequestingTimeModules) {
      fetchModules();
    }
  }, [classData, timeModules, isRequestingTimeModules, t]);

  return {
    isLoading: isRequestingTimeModules || isLoading,
    classModulesList,
  };
}
