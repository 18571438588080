import { Box, useMobile } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';

export interface Props {
  courseName: string;
  sectionName: string;
  sectionAttendance: string;
}

export default function SectionInfoBox({
  courseName,
  sectionName,
  sectionAttendance,
}: Props) {
  const isMobile = useMobile();
  const { t } = useTranslation();

  return (
    <Box
      color="primary"
      variant="outlined"
      style={{ borderRadius: 4 }}
      className={`py-2 px-3 ${isMobile && 'mt-4 w-100'}`}
      body={
        <div className="d-flex justify-content-between">
          <Box
            color="primary"
            variant="outlined"
            className="p-0 border-0 w-50 text-uppercase"
            title={t(`common.terms.course`)}
            style={{ minWidth: isMobile ? 0 : 160 }}
            body={<p className="fs-14 mb-0 text-capitalize">{courseName}</p>}
          />
          <Box
            color="primary"
            variant="outlined"
            className="p-0 border-0 w-50 text-uppercase"
            title={t(`common.terms.section`)}
            style={{ minWidth: isMobile ? 0 : 130 }}
            body={<p className="fs-14 mb-0 text-capitalize">{sectionName}</p>}
          />
          <Box
            color="primary"
            variant="outlined"
            className="p-0 border-0 w-50 text-uppercase"
            title={t(`common.terms.courseAttendance`)}
            style={{ minWidth: isMobile ? 0 : 130 }}
            body={
              <p className="fs-14 mb-0 text-capitalize">{sectionAttendance}</p>
            }
          />
        </div>
      }
    />
  );
}
