import { useEffect, useState } from 'react';

import { getServerTimezone } from '../api/requests/settings';
import { ServerSettings } from '../types/Settings';

const DEFAULT: ServerSettings = {
  offset: -180,
  dateLocalOffset: '',
  dateNow: new Date().toString(),
};

export default function useServerTimeSettings() {
  const [settings, setSettings] = useState<ServerSettings>(DEFAULT);

  useEffect(() => {
    const requestServerUTC = async () => {
      const { data } = await getServerTimezone();
      if (data) {
        setSettings(data);
      }
    };

    requestServerUTC();
  }, []);

  return settings;
}
