import { AxiosError, AxiosRequestConfig, Method } from 'axios';

import { UserSessionEvent } from '../types/userTypes';
import { getJWT } from '../utils/auth';
import { logger } from '../utils/logger';
import API from './api';

type RequestOptionsType = {
  method?: Method;
  data?: any;
  formData?: any;
  authRequired?: boolean;
  retry?: boolean;
  params?: any;
  responseType?: AxiosRequestConfig['responseType'];
};

const getHeaders = (authRequired: boolean) => {
  const headers: any = {};
  if (authRequired) {
    const token = getJWT();
    headers.Authorization = `Bearer ${token}`;
  }
  return headers;
};

type RequireOne<T, K extends keyof T> = Omit<T, K> & Required<Pick<T, K>>;

export type AxiosErrorWithResponse<T = any> = RequireOne<
  AxiosError<T>,
  'response'
>;

type AxiosResultErrorHttp<T = any> = {
  code: 'HTTP_ERROR';
  error: AxiosErrorWithResponse;
  status: number;
  data?: T;
};

type AxiosResultErrorConnection = {
  code: 'CONNECTION';
  error: AxiosError;
  data?: null;
};

export type AxiosResultDefaultError =
  | AxiosResultErrorHttp
  | AxiosResultErrorConnection;

export type AxiosResult<
  T,
  E extends { code: string; data?: any } = AxiosResultDefaultError
> = { data: T; error?: false } | { data?: null; error: E };

const request = async <T = any>(
  url: string,
  options: RequestOptionsType = {},
): Promise<AxiosResult<T>> => {
  const {
    method = 'get',
    data = {},
    authRequired = true,
    params = {},
    responseType,
  } = options;
  try {
    const response = await API.request<T>({
      url: url,
      method: method,
      data: data,
      params: params,
      headers: getHeaders(authRequired),
      responseType,
    });
    return { data: response.data, error: false };
  } catch (error: any) {
    logger(error);

    if (error.response) {
      // Token invalido
      if (error.response.status === 401 && authRequired) {
        const sessionExpiredEvent = new CustomEvent(UserSessionEvent.EXPIRED);
        window.dispatchEvent(sessionExpiredEvent);
      }

      return {
        error: {
          code: 'HTTP_ERROR',
          status: error.response.status,
          data: error.response.data,
          error,
        },
      };
    }
    return {
      error: {
        code: 'CONNECTION',
        error,
      },
    };
  }
};

export default request;
