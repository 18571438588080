import { addToast } from '@octano/global-ui';
import clsx from 'clsx';
import { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { saveAttendants } from '../../../api/requests/attendance';
import { SectionHistoryRecord } from '../../../types/AttendanceTracking';
import { AttendanceStatus, AttendantRecord } from '../../../types/Class';
import {
  JustificationModal,
  StatusBox,
  StatusSelector,
  TrackingTableErrorMsg,
} from '../parts';
import InfoWithTooltip from './InfoWithTooltip';

export type MonthsRow = { name: string; modulesCount: number }[];

export type DaysRow = {
  dayName: string;
  dayNumber: number;
  modulesCount: number;
  activityName: string;
}[];
export type ModulesRow = Array<string[]>;

export type StudentRow = {
  id: number;
  fullName: string;
  attendance: StudentAttendanceRecord[];
  totalClasses: number;
  totalMissedClasses: number;
  sectionHistory: SectionHistoryRecord[];
};

export type StudentAttendanceRecord = {
  id?: number;
  date: string;
  lessonId?: number;
  moduleId: number;
  moduleName: string;
  status: AttendanceStatus | null;
  observation?: string;
};

export interface HeadersObj {
  months: MonthsRow;
  days: DaysRow;
  modules: ModulesRow;
}

interface SliceIndexes {
  monthsStartIdx?: number;
  monthsEndIdx?: number;
  daysEndIdx?: number;
  daysStartIdx?: number;
  modulesStartIdx?: number;
  modulesEndIdx?: number;
  attendancesStartIdx?: number;
  attendancesEndIdx?: number;
}

interface Props {
  headers: HeadersObj;
  rows: StudentRow[];
  sectionId: number;
  courseId: number;
  monthIndex?: number;
  readonly?: boolean;
  onStatusChange?: (record: StudentAttendanceRecord) => void;
}

export default function TrackingTable({
  rows = [],
  headers,
  sectionId,
  courseId,
  monthIndex,
  readonly = false,
  onStatusChange = () => null,
}: Props) {
  const { t } = useTranslation();

  const containerRef = useRef<HTMLDivElement>(null);
  const [
    scrollingOverAttendance,
    setScrollingOverAttendance,
  ] = useState<boolean>(false);
  const [justifyingAttendance, setJustifyingAttendance] = useState<
    (StudentAttendanceRecord & { rowIdx: number }) | undefined
  >();

  useEffect(() => {
    const checkScrollOver = (ev: Event) => {
      const newValue = (ev.target as HTMLDivElement).scrollLeft > 8;
      setScrollingOverAttendance(newValue);
    };

    containerRef.current?.addEventListener('scroll', checkScrollOver);
    const elRef = containerRef.current;

    return () => elRef?.removeEventListener('scroll', checkScrollOver);
  }, []);

  const updateStatus = async (
    data?: StudentAttendanceRecord & { studentId: number },
  ) => {
    if (!data || !data.status) return;

    const res = await saveAttendants([data as AttendantRecord]);

    if (res.error) {
      addToast({
        icon: 'information',
        color: 'danger',
        text: t(`attendanceTracking.errors.updatingAttendance`),
        autoClose: 3000,
      });
    } else {
      onStatusChange(data);
      addToast({
        icon: 'check',
        color: 'success',
        text: t(`attendanceTracking.success.updatingAttendance`),
        autoClose: 1000,
      });
    }
  };

  const handleStatusUpdate = (
    rowIdx: number,
    attendanceRecord: StudentAttendanceRecord,
  ) => {
    const data = { studentId: rows[rowIdx].id, ...attendanceRecord };

    if (attendanceRecord.status === AttendanceStatus.JUSTIFIED) {
      setJustifyingAttendance({ ...data, rowIdx });
    } else {
      updateStatus({ ...data, observation: undefined });
    }
  };

  const handleModalConfirm = (justification: string) => {
    if (justifyingAttendance) {
      const data = {
        studentId: rows[justifyingAttendance.rowIdx].id,
        ...justifyingAttendance,
      };
      updateStatus({
        ...data,
        observation: justification,
      });

      setJustifyingAttendance(undefined);
    }
  };

  const indexes = useMemo(
    function getSliceIndexes(): SliceIndexes {
      if (
        !headers.months ||
        monthIndex === undefined ||
        monthIndex < 0 ||
        monthIndex > headers.months.length - 1
      )
        return {};

      const slicedMonthModulesCount = headers.months.slice(
        monthIndex,
        monthIndex + 1,
      )[0]?.modulesCount;

      const modulesCountBeforeIdx = headers.months
        .slice(0, monthIndex)
        .reduce((acum, curr) => acum + curr.modulesCount, 0);

      let daysStartIdx = 0;
      let acum = 0;

      for (let i = 0; i < headers.days.length; i++) {
        if (acum !== modulesCountBeforeIdx) {
          acum += headers.days[i].modulesCount;
        } else {
          daysStartIdx = i;
          acum = 0;
          break;
        }
      }

      let daysCount = 0;

      for (let i = daysStartIdx; i < headers.days.length; i++) {
        if (acum === slicedMonthModulesCount) break;
        acum += headers.days[i].modulesCount;
        daysCount += 1;
      }

      const modulesStartIdx = daysStartIdx;
      const modulesEndIdx = modulesStartIdx + daysCount;

      const attendancesStartIdx = modulesCountBeforeIdx;
      const attendancesEndIdx = attendancesStartIdx + slicedMonthModulesCount;

      return {
        monthsStartIdx: monthIndex,
        monthsEndIdx: monthIndex + 1,
        daysEndIdx: daysStartIdx + daysCount,
        daysStartIdx,
        modulesStartIdx,
        modulesEndIdx,
        attendancesStartIdx,
        attendancesEndIdx,
      };
    },
    [headers, monthIndex],
  );

  const justifyingAttendanceInfo = {
    studentName: justifyingAttendance
      ? rows[justifyingAttendance.rowIdx]?.fullName
      : '',
    moduleName: justifyingAttendance?.moduleName || '',
    date: justifyingAttendance?.date || '',
  };

  if (!headers.months.length) {
    return (
      <TrackingTableErrorMsg
        boldTitle={
          !headers.months.length
            ? t(`attendanceTracking.errors.noTrackingInfo.boldTitle`)
            : t(`attendanceTracking.errors.retrievingTrackingInfo.boldTitle`)
        }
        title={
          !headers.months.length
            ? t(`attendanceTracking.errors.noTrackingInfo.title`)
            : t(`attendanceTracking.errors.retrievingTrackingInfo.title`)
        }
        titleNextLine={
          !headers.months.length
            ? ''
            : t(`attendanceTracking.errors.noTrackingInfo.titleNextLine`)
        }
      />
    );
  }

  return (
    <>
      <JustificationModal
        isOpen={!!justifyingAttendance}
        attendanceInfo={justifyingAttendanceInfo}
        onCancel={() => setJustifyingAttendance(undefined)}
        onConfirm={handleModalConfirm}
      />
      <div className="attendance-tracking-table-wrapper my-4">
        <div className="table-container" ref={containerRef}>
          <table className="students-table">
            <thead>
              <tr>
                <th scope="col" rowSpan={3} className="name-header">
                  {t(`common.terms.studentName`)}
                </th>
                <th></th>
                <th
                  scope="col"
                  rowSpan={2}
                  colSpan={1}
                  className="attendance-header"
                >
                  {t(`common.terms.attendance`)}
                </th>
              </tr>
              <tr></tr>
              <tr>
                {/* Cuando se incluya el % obligatorio deben devolverse las 2 lineas comentadas */}
                <th scope="col" colSpan={2}>
                  {t(`common.terms.total`)}
                </th>
                {/* <th scope="col">{t(`common.terms.total`)}</th> */}
                {/* <th scope="col">{t(`common.terms.mandatory`)}</th> */}
              </tr>
            </thead>
            <tbody>
              {rows.map((studentRow) => {
                const {
                  id,
                  fullName,
                  totalMissedClasses,
                  totalClasses,
                  sectionHistory,
                } = studentRow;
                const totalAttendances = totalClasses - totalMissedClasses;
                const totalPercentage = (totalAttendances * 100) / totalClasses;

                const sectionChanges = sectionHistory
                  .filter((r) => r.startSection?.course.id === courseId)
                  .filter((r) => r.typeModification === 'update-record');

                let tooltipText = '';
                const prefix = 'attendanceTracking.sectionChange';

                if (sectionChanges.length > 0) {
                  const lastChange = sectionChanges.slice(-1)[0];
                  tooltipText =
                    lastChange.endSection?.id === sectionId
                      ? t(`${prefix}.comesFrom`, {
                          sectionName: lastChange.startSection?.name,
                        })
                      : t(`${prefix}.wentTo`, {
                          sectionName: lastChange.endSection?.name,
                        });
                }

                return (
                  <tr key={id}>
                    <td>
                      <p className="mb-0">{fullName}</p>
                    </td>
                    <td>
                      <InfoWithTooltip
                        show={Boolean(tooltipText)}
                        tooltipText={tooltipText}
                        tooltipId={`${id}`}
                      />
                    </td>
                    {/* Se debe eliminar el colspan cuando se incluya el % de asistencia obligatoria */}
                    <td colSpan={2}>
                      <p className="mb-0">
                        {Math.round(totalPercentage)}% ({totalAttendances}/
                        {totalClasses})
                      </p>
                    </td>
                    {/* Por ahora no se esta incluyendo el % de asistencia obligatoria */}
                    {/* <td>
                      <p className="mb-0">100%</p>
                    </td> */}
                  </tr>
                );
              })}
              {rows.length === 0 && (
                <tr>
                  <td>&nbsp;</td>
                </tr>
              )}
            </tbody>
          </table>

          <table
            className={`attendance-table ${
              scrollingOverAttendance ? 'scrolling-over' : ''
            }`}
          >
            <thead>
              <tr>
                {headers.months
                  .slice(indexes.monthsStartIdx, indexes.monthsEndIdx)
                  .map((month, idx) => (
                    <th
                      scope="col"
                      colSpan={month.modulesCount}
                      key={`${month.name}-${idx}`}
                      className="month-header"
                      title={month.name}
                    >
                      {month.modulesCount <= 3
                        ? month.name.slice(0, 3)
                        : month.name}
                    </th>
                  ))}
                <th>&nbsp;</th>
              </tr>
              <tr>
                {headers.days
                  .slice(indexes.daysStartIdx, indexes.daysEndIdx)
                  .map((day, idx) => (
                    <th
                      scope="col"
                      colSpan={day.modulesCount}
                      key={`${day.dayName}-${idx}`}
                    >
                      {day.dayName} &nbsp;
                      {day.dayNumber}
                      <br />
                      <span
                        className={clsx(
                          'd-block text-center text-uppercase w-10 mx-auto',
                          day.modulesCount >= 3 && 'text-truncate',
                        )}
                        title={day.activityName}
                      >
                        {day.modulesCount >= 3
                          ? day.activityName
                          : day.activityName.slice(0, 3)}
                      </span>
                    </th>
                  ))}
                <th>&nbsp;</th>
              </tr>
              <tr>
                {headers.modules
                  .slice(indexes.modulesStartIdx, indexes.modulesEndIdx)
                  .map((modulesList, idx) => (
                    <Fragment key={idx}>
                      {modulesList.map((module, idx) => (
                        <th
                          key={`${module}-${idx}`}
                          scope="col"
                          className={clsx('module-header', {
                            'first-module': idx === 0,
                            'last-module': idx === modulesList.length - 1,
                          })}
                        >
                          {module}
                        </th>
                      ))}
                    </Fragment>
                  ))}
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {rows.map((studentRow: StudentRow, rowIdx: number) => (
                <tr key={studentRow.id}>
                  {studentRow.attendance
                    .slice(
                      indexes.attendancesStartIdx,
                      indexes.attendancesEndIdx,
                    )
                    .map((attendanceRecord: StudentAttendanceRecord, idx) => {
                      if (!attendanceRecord.status)
                        return (
                          <td key={`null-${idx}`}>
                            <StatusBox status={attendanceRecord.status} />
                          </td>
                        );

                      return (
                        <td key={attendanceRecord.id}>
                          <StatusSelector
                            readonly={!!readonly}
                            status={attendanceRecord.status}
                            onChange={(status) =>
                              handleStatusUpdate(rowIdx, {
                                ...attendanceRecord,
                                status,
                              })
                            }
                          />
                        </td>
                      );
                    })}
                  <td style={{ width: 'auto' }}></td>
                </tr>
              ))}
              {rows.length === 0 && indexes && (
                <tr>
                  {new Array(headers.modules.flat().length)
                    .fill(' ')
                    .map((_, idx) => (
                      <td style={{ width: !idx ? 44 : 36 }}>&nbsp;</td>
                    ))}
                  <td style={{ width: 'auto' }}>&nbsp;</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
