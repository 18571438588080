import { useCallback, useContext } from 'react';

import { UserContext } from '../providers/UserProvider';
import { UserActionTypes, UserState } from '../types/userTypes';
import { isUserAuthorizedTo, removeJWT } from '../utils/auth';

/**
 * Provee el estado y el manejo de estado del usuario autenticado
 */
export const useUserState = () => {
  const { state, dispatch } = useContext(UserContext);

  const resetUserState = useCallback(async () => {
    removeJWT();
    dispatch({
      type: UserActionTypes.RESET_STATE,
    });
  }, [dispatch]);

  const setIsLogged = useCallback(
    (isLogged: boolean) => {
      dispatch({
        type: UserActionTypes.SET_IS_LOGGED,
        payload: isLogged,
      });
    },
    [dispatch],
  );

  const setIsSessionExpired = useCallback(
    (isSessionExpired: boolean) => {
      dispatch({
        type: UserActionTypes.SET_IS_SESSION_EXPIRED,
        payload: isSessionExpired,
      });
    },
    [dispatch],
  );

  const setUserFullName = useCallback(
    (fullName: string) => {
      dispatch({
        type: UserActionTypes.SET_FULL_NAME,
        payload: fullName,
      });
    },
    [dispatch],
  );

  const setUserData = useCallback(
    (data: Partial<UserState['userData']>) => {
      dispatch({
        type: UserActionTypes.SET_USER_DATA,
        payload: data,
      });
    },
    [dispatch],
  );

  const isAuthorizedTo = useCallback(
    (requiredPermissions: string[], allPermisionsRequired?: boolean) => {
      return isUserAuthorizedTo(
        state?.userData?.permissions || [],
        requiredPermissions,
        allPermisionsRequired,
      );
    },
    [state?.userData?.permissions],
  );

  return {
    ...state,
    resetUserState,
    setIsLogged,
    setIsSessionExpired,
    setUserFullName,
    setUserData,
    isAuthorizedTo,
  };
};
