import { Button, Icon, Modal } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';

interface Props {
  isOpen: boolean;
  onConfirm?: () => void;
  texts: { title: string; description: string };
}

export default function NoResultsModal({
  isOpen,
  onConfirm = () => null,
  texts = { title: '', description: '' },
}: Props) {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} toggle={() => null}>
      <div className="d-flex flex-column align-items-center text-center">
        <Icon name="warning" size={40} />
        <h1 className="text-dark fw-600 fs-20 mt-5">{texts.title}</h1>
        <p className="fs-16 lh-30 mt-3 mb-5">{texts.description}</p>
        <Button text={t(`common.btnGotIt`)} onClick={onConfirm} fullwidth />
      </div>
    </Modal>
  );
}
