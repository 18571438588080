import { Button } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';

import SectionInfoBox, { Props as SectionInfoProps } from './SectionInfoBox';

interface Props {
  sectionInfo: SectionInfoProps;
  onGoBack?: () => void;
}

export default function Header({ sectionInfo, onGoBack = () => null }: Props) {
  const { t } = useTranslation();
  return (
    <>
      <div className="d-flex flex-wrap align-items-center justify-content-between">
        <Button
          icon="back"
          onClick={() => onGoBack()}
          outlined
          rounded
          size="sm"
          text={t(`common.btnGoBack`)}
          className="mr-3"
        />

        <SectionInfoBox
          courseName={sectionInfo?.courseName || ''}
          sectionName={sectionInfo?.sectionName || ''}
          sectionAttendance={sectionInfo?.sectionAttendance || ''}
        />
      </div>
    </>
  );
}
