import dayjs from 'dayjs';

import {
  Assistant,
  EmptyAssistant,
  Header,
  Module,
  StudentsAssistant,
} from '../../types/AttendanceTracking';
import { AttendanceStatus } from '../../types/Class';
import { HeadersObj, StudentAttendanceRecord } from './parts/TrackingTable';

export const mapPeriod = (
  period: { monthNumber: number; month: string },
  idx: number,
) => {
  let label = dayjs()
    .month(period.monthNumber - 1)
    .format('MMMM');
  label = label[0].toUpperCase() + label.slice(1);

  return {
    label,
    value: `${idx}`,
  };
};

export const mapAttendance = (
  attendance: Assistant | EmptyAssistant,
): StudentAttendanceRecord => {
  if (attendance.status) {
    return {
      id: attendance.id,
      status: attendance.status,
      observation: attendance.observation,
      lessonId: attendance.lesson?.id,
      moduleId: attendance.module.id,
      moduleName: attendance.module.name,
      date: attendance.date,
    };
  } else {
    return {
      status: attendance.status,
      moduleId: attendance.module.id,
      moduleName: attendance.module.name,
      date: attendance.date,
    };
  }
};

export const mapStudent = ({ student, assistants }: StudentsAssistant) => ({
  id: student.id,
  fullName: student?.account?.fullName || '',
  attendance: assistants.map(mapAttendance) || [],
  totalClasses: Math.abs(
    assistants.length - assistants.filter((a) => a.status === null).length,
  ),
  totalMissedClasses: assistants.filter(
    (att) => att.status === AttendanceStatus.ABSENT,
  )?.length,
  sectionHistory: student.sectionHistories,
});

export const getTableHeadersObj = (headers: Header[]): HeadersObj => {
  const months: HeadersObj['months'] = [];
  const days: HeadersObj['days'] = [];
  const modules: HeadersObj['modules'] = [];

  /* Guardamos una ref al mes anterior para saber si hemos cambiado de mes al recorrer las fechas */
  let prevMonth: number = headers[0]?.monthNumber;
  let modulesAcum: number = 0;

  /* Armamos las cabeceras de la tabla segun las fechas de clase */
  headers.forEach((record, idx) => {
    const dayName = record.day;
    const dayNumber = Number(dayjs(record.date).format('DD') || '');
    days.push({
      dayName,
      dayNumber,
      modulesCount: record.modules.length,
      activityName: record.activity.name,
    });

    const shortenModules = record.modules.map((tm: Module) => tm.shortening);
    modules.push(shortenModules);

    /* Hay que contar la cantidad de modulos para saber los colspan de la tabla */
    const finishedMonthCount = record.monthNumber !== prevMonth;

    /* Insertamos en el arreglo cuando ya no hay modulos que contar o cuando llegamos al ultimo mes*/
    if (finishedMonthCount) {
      const name = dayjs()
        .month(prevMonth - 1)
        .format('MMMM');

      months.push({
        name,
        modulesCount: modulesAcum,
      });

      modulesAcum = record.modules.length;
      prevMonth = record.monthNumber;
    } else {
      modulesAcum += record.modules.length;
    }

    if (idx === headers.length - 1) {
      const name = dayjs()
        .month(record.monthNumber - 1)
        .format('MMMM');

      months.push({
        name,
        modulesCount: modulesAcum,
      });
    }
  });

  return { months, days, modules };
};
