import { Icon } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';

export default function NoResultsMsg() {
  const { t } = useTranslation();

  return (
    <div className="d-flex align-items-center justify-content-center">
      <div style={{ maxWidth: 346, marginTop: 70, marginBottom: 65 }}>
        <Icon name="information" color="primary" size="61px" />
        <p className="text-dark fw-700 mt-4 mb-3 fs-18">
          {t(`classesNoResultsTitles.title`)}
        </p>
        <p className="text-light lh-30 mb-0 fs-16">
          {t(`classesNoResultsTitles.subtitle`)}
        </p>
        <p className="text-light fs-16">
          {t(`classesNoResultsTitles.recommendation`)}
        </p>
      </div>
    </div>
  );
}
