import { Button, OutlinedSelect, addToast, useMobile } from '@octano/global-ui';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import { updateClassStatus } from '../../api/requests/classes';
import useAttendanceList from '../../hooks/useAttendanceList';
import useClassData from '../../hooks/useClassData';
import useClassTimeModulesList from '../../hooks/useClassTimeModulesList';
import useDebounce from '../../hooks/useDebounce';
import useSectionStudentsList from '../../hooks/useSectionStudentsList';
import {
  AttendanceStatus,
  AttendantRecord,
  ClassStatus,
} from '../../types/Class';
import { AttendantsTable, ClassInfoBox, StatusToggler } from './parts';
import CloseConfirmationModal from './parts/CloseConfirmationModal';
import JustificationModal from './parts/JustificationModal';

export default function ClassAttendance() {
  const { t } = useTranslation();

  const isMobile = useMobile();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { classData, isLoading: isLoadingClassData } = useClassData(Number(id));
  const {
    classModulesList,
    isLoading: isLoadingModules,
  } = useClassTimeModulesList(classData);
  const [selectedModule, setSelectedModule] = useState<{
    label: string;
    value: number | string;
  }>();
  const {
    studentsList,
    isLoading: isLoadingStudents,
    error: hadStudentsListError,
  } = useSectionStudentsList(classData?.sectionSchedule.section.id || 0);
  const [currentStudents, setCurrentStudents] = useState<any[]>([]);
  const [showError, setShowError] = useState(false);

  const [pendingStudents, setPendingStudents] = useState<string[]>();
  const [justificatingStudent, setJustificatingStudent] = useState<any>();

  const [attendanceUpdates, setAttendanceUpdates] = useState<AttendantRecord[]>(
    [],
  );
  const debouncedAttendanceUpdates = useDebounce(attendanceUpdates, 1000);

  /* Aca se restringe la cantidad de veces que puede salir un request de update en un intervalo de tiempo */
  useEffect(() => {
    if (debouncedAttendanceUpdates?.length) {
      updateAttendance(debouncedAttendanceUpdates).then(() =>
        setAttendanceUpdates([]),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedAttendanceUpdates]);

  /* Se necesitan para pedir la asistencia por modulo */
  const timeModulesIds = useMemo(
    () =>
      classModulesList
        .filter((tm: any) => tm.value !== 'all')
        .map((tm: any) => Number(tm.value)),
    [classModulesList],
  );

  const {
    attendanceList,
    isLoading: isLoadingAttendanceList,
    error: hadAttendanceListError,
    updateAttendance,
  } = useAttendanceList({
    classId: classData?.id || 0,
    timeModulesIds,
  });

  /* Determina si un estudiante tiene el mismo status para todos los modulos */
  const hasSameStatusInAllModules = useCallback(
    (studentId: number) => {
      const studentAttendance = attendanceList.filter(
        (ar) => ar.student.id === studentId,
      );
      const allModulesWithStatus =
        studentAttendance.length === classModulesList.length - 1;
      const allWithSameStatus = studentAttendance.every(
        (att) => att.status === studentAttendance[0]?.status,
      );
      return allModulesWithStatus && allWithSameStatus;
    },
    [attendanceList, classModulesList],
  );

  /* Determina si el estudiante tiene una observacion que aplica para todas sus asistencias registradas */
  const hasSameObservationInAllAttendances = useCallback(
    (studentId: number) => {
      const studentAttendance = attendanceList.filter(
        (ar) => ar.student.id === studentId,
      );

      return studentAttendance.every(
        (attendance) =>
          attendance.observation === studentAttendance[0].observation,
      );
    },
    [attendanceList],
  );

  /* Inicializando el valor del select cuando la lista de modulos ya este disponible */
  useEffect(() => {
    if (!selectedModule) setSelectedModule(classModulesList[0]);
  }, [classModulesList, selectedModule]);

  /* Aca se calculan los estudiantes que se mostraran en la tabla con su estado */
  useEffect(() => {
    const updateCurrentStudents = async () => {
      if (!studentsList || !attendanceList || !selectedModule) return;

      let newList: any[] = [];

      for (const element of studentsList) {
        const student = element;
        const attendanceRecord = attendanceList.find((record) => {
          return (
            record.module.id === selectedModule.value &&
            record.student.id === student.id
          );
        });

        // Si hay un registro de ese estudiante para el modulo seleccionado
        if (attendanceRecord) {
          newList.push({
            id: attendanceRecord?.id,
            status: attendanceRecord?.status,
            lessonId: attendanceRecord?.lesson.id,
            studentId: attendanceRecord?.student.id,
            moduleId: attendanceRecord?.module.id,
            observation: attendanceRecord?.observation,
            fullName: student.account?.fullName,
            postulantPhotoId: student.postulant?.photo?.id,
          });
        } else if (hasSameStatusInAllModules(student.id)) {
          const aRecord = attendanceList.find(
            (aRecord) => aRecord.student.id === student.id,
          );
          const observation = hasSameObservationInAllAttendances(student.id)
            ? aRecord?.observation
            : t(`classAttendance.multipleJustificationsMsg`);

          newList.push({
            status: aRecord?.status,
            lessonId: classData?.id,
            studentId: student.id,
            moduleId: selectedModule?.value,
            observation: observation,
            fullName: student.account?.fullName,
            postulantPhotoId: student.postulant?.photo?.id,
          });
        } else {
          newList.push({
            status: null,
            lessonId: classData?.id,
            studentId: student.id,
            moduleId: selectedModule.value,
            fullName: student.account?.fullName,
            postulantPhotoId: student.postulant?.photo?.id,
          });
        }
      }

      /* Ordenando por nombre de forma ascendente (letra a letra) */
      newList = newList.sort((st1, st2) =>
        `${st1.fullName}`.toLowerCase() < `${st2.fullName}`.toLowerCase()
          ? -1
          : 1,
      );

      setCurrentStudents(newList);
    };

    updateCurrentStudents();
  }, [
    selectedModule,
    classModulesList,
    studentsList,
    attendanceList,
    classData?.id,
    hasSameStatusInAllModules,
    hasSameObservationInAllAttendances,
    t,
  ]);

  /* Aca se calcula el status global que se muestra encima de la tabla */
  const moduleStatus = useMemo(() => {
    if (selectedModule?.value !== 'all') {
      const firstStatus = currentStudents[0]?.status;
      return currentStudents.every((student) => student?.status === firstStatus)
        ? firstStatus
        : null;
    } else {
      const firstStatus = attendanceList[0]?.status;
      const totalExpectedRecords =
        (classModulesList.length - 1) * studentsList.length;
      return attendanceList.every((record) => record?.status === firstStatus) &&
        attendanceList.length === totalExpectedRecords
        ? firstStatus
        : null;
    }
  }, [
    attendanceList,
    classModulesList.length,
    currentStudents,
    selectedModule,
    studentsList.length,
  ]);

  /* Info que va en el box de la vista y del modal de justificacion */
  const classInfo = {
    name: classData?.sectionSchedule.section.course.name || '',
    section: classData?.sectionSchedule.section.name || '',
  };

  /* Mostrar error si alguna de las peticiones de listado falla */
  useEffect(() => {
    if (!showError && (hadAttendanceListError || hadStudentsListError)) {
      addToast({
        icon: 'information',
        color: 'danger',
        text: t(`classAttendance.errors.retrievingAttendaceList`),
      });
      setShowError(true);
    }
  }, [showError, hadAttendanceListError, hadStudentsListError, t]);

  const handleClassFinalization = () => {
    const pendingStudents = studentsList
      .filter((student) => {
        const studentAttendance = attendanceList.filter(
          (attendanceRecord) => attendanceRecord.student.id === student.id,
        );
        const hasPendingAssitance =
          studentAttendance.length !== classModulesList.length - 1;

        return hasPendingAssitance;
      })
      .map((student) => student.account?.fullName || '');

    if (pendingStudents.length) {
      setPendingStudents(pendingStudents);
    } else {
      endClass();
    }
  };

  const handleSingleStatusChange = (attendanceRow: any) => {
    /* Cuando el estado es justificado se abre el modal antes de actualizar */
    if (
      attendanceRow.status === AttendanceStatus.JUSTIFIED &&
      !justificatingStudent
    ) {
      setJustificatingStudent(() => attendanceRow);
      return;
    }

    setJustificatingStudent(() => undefined);

    const observation =
      attendanceRow.status === AttendanceStatus.JUSTIFIED
        ? attendanceRow.observation
        : undefined;
    if (selectedModule?.value === 'all') {
      const newRecords: AttendantRecord[] = classModulesList
        ?.filter((module) => module.value !== 'all')
        .map((module) => ({
          observation,
          moduleId: Number(module.value),
          lessonId: classData?.id || 0,
          status: attendanceRow.status,
          studentId: attendanceRow.studentId,
          id: attendanceList.find(
            (att) =>
              att.module.id === Number(module.value) &&
              att.student.id === attendanceRow.studentId,
          )?.id,
        }));

      setAttendanceUpdates((prevUpdates) => {
        const filteredUpdates = prevUpdates.filter(
          (au) => au.studentId !== attendanceRow.studentId,
        );

        return [...filteredUpdates, ...newRecords];
      });
    } else {
      const newRecord = {
        observation,
        moduleId: attendanceRow.moduleId,
        lessonId: classData?.id || 0,
        status: attendanceRow.status,
        studentId: attendanceRow.studentId,
        id: attendanceList.find(
          (att) =>
            att.module.id === attendanceRow.moduleId &&
            att.student.id === attendanceRow.studentId,
        )?.id,
      };

      setAttendanceUpdates((prevUpdates) => {
        const filteredUpdates = prevUpdates.filter(
          (au) =>
            au.studentId !== attendanceRow.studentId ||
            au.moduleId !== attendanceRow.moduleId,
        );

        return [...filteredUpdates, newRecord];
      });
    }
  };

  const handleJustificationChange = (justification: string) => {
    handleSingleStatusChange({
      ...justificatingStudent,
      observation: justification,
    });
  };

  const handleGlobalStatusChange = (newStatus: AttendanceStatus) => {
    currentStudents.forEach((student) => {
      handleSingleStatusChange({
        ...student,
        status: newStatus,
        moduleId: selectedModule?.value,
        observation: undefined,
      });
    });
  };

  const endClass = async () => {
    const res = await updateClassStatus({
      id: classData?.id ?? 0,
      status: ClassStatus.CLOSED,
    });

    if (res.error) {
      return addToast({
        icon: 'information',
        color: 'danger',
        text: t(`classAttendance.errors.closingClass`),
      });
    } else {
      let newRecords: AttendantRecord[] = [];

      studentsList.forEach((student) => {
        const studentAttendanceList = attendanceList.filter(
          (attendance) => attendance.student.id === student.id,
        );

        const uncheckedModules = classModulesList.slice(1).filter((module) => {
          const alreadyHasAttendanceRecord = !!studentAttendanceList.find(
            (attendance) => attendance.module.id === module.value,
          );
          return !alreadyHasAttendanceRecord;
        });

        const attendancesToSend = uncheckedModules.map((module) => ({
          moduleId: Number(module.value),
          lessonId: classData?.id || 0,
          studentId: student.id || 0,
          status: AttendanceStatus.ABSENT,
        }));

        newRecords = [...newRecords, ...attendancesToSend];
      });

      await updateAttendance(newRecords);
      history.goBack();
    }
  };

  const loadingStudentsIds = useMemo(
    () => attendanceUpdates.map((attUpd) => attUpd.studentId),
    [attendanceUpdates],
  );

  return (
    <>
      <JustificationModal
        isOpen={!!justificatingStudent}
        initialMsg={justificatingStudent?.observation || ''}
        classInfo={classInfo}
        onConfirm={(j) => handleJustificationChange(j)}
        onCancel={() => setJustificatingStudent(undefined)}
      />
      <CloseConfirmationModal
        isOpen={!!pendingStudents}
        pendingStudents={pendingStudents}
        onCancel={() => setPendingStudents(undefined)}
        onConfirm={() => endClass()}
      />
      <div className="m-3 py-4 px-3 bg-white rounded">
        <div className="d-flex flex-wrap align-items-center justify-content-between">
          <Button
            icon="back"
            onClick={() => history.goBack()}
            outlined
            rounded
            size="sm"
            text={t(`common.btnGoBack`)}
            className="mr-3 text-uppercase"
          />

          <ClassInfoBox {...classInfo} />
        </div>
        <h2 className="text-primary fs-20 fw-700 mt-4">
          {t(`classAttendance.pageTitle`)}
        </h2>
        <p className="fs-16">{t(`classAttendance.pageDescription`)}</p>
        <p className="fs-16">{t(`classAttendance.pageSubDescription`)}</p>
        <div className="d-flex justify-content-between align-items-center flex-wrap mb-2">
          <div
            className="d-flex align-items-center flex-wrap"
            style={{ gap: isMobile ? 0 : '1.5rem' }}
          >
            <div className="modules-selector pt-4">
              <OutlinedSelect
                name="module"
                options={classModulesList}
                value={selectedModule}
                onChange={setSelectedModule}
                isClearable={false}
                isSearchable={false}
                disabled={isLoadingModules}
              />
            </div>
            <p className="fs-14 mb-0 modules-selector-description">
              {t(`classAttendance.globalSelectorDescription`)}
            </p>
          </div>

          <div className="d-flex flex-wrap align-items-center justify-content-between">
            <h3
              className={`text-primary fs-16 fw-700 mb-0 mr-4 ${
                isMobile && 'w-100 mt-4 mb-2'
              }`}
            >
              {t(`classAttendance.markAllAs`)}
            </h3>
            <div
              className={`d-flex ${isMobile ? 'w-100' : ''}`}
              style={{ marginRight: '0.75rem' }}
            >
              <StatusToggler
                value={moduleStatus}
                onChange={handleGlobalStatusChange}
                excludeStatuses={[AttendanceStatus.JUSTIFIED]}
              />
            </div>
          </div>
        </div>

        <div className={isMobile ? 'mt-4' : ''}>
          <AttendantsTable
            rows={showError ? [] : currentStudents}
            isLoading={
              isLoadingClassData ||
              isLoadingModules ||
              isLoadingStudents ||
              isLoadingAttendanceList
            }
            onStatusChange={handleSingleStatusChange}
            loadingStudentsIds={loadingStudentsIds}
          />
        </div>

        <div className="end-btns-container mt-5">
          <Button
            color="primary"
            text={t(`common.btnFinalize`)}
            className="btn"
            onClick={handleClassFinalization}
          />
        </div>
      </div>
    </>
  );
}
