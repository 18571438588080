import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { TeachaerSectionCourseOfGradeEntry } from '../../../api/requests/gradeEntry';
import InfoTags from '../../../components/Header/InfoTags';

export default function CreateEvaluationGradeEntryInfoTags({
  data,
}: {
  data: TeachaerSectionCourseOfGradeEntry;
}) {
  const { t } = useTranslation();

  const tags = useMemo(
    () => [
      {
        label: t('common.terms.shortening'),
        value: data.Course_shortening,
      },
      {
        label: t('common.terms.course'),
        value: data.Course_name,
      },
      {
        label: t('common.terms.section'),
        value: data.Section_name,
      },
      {
        label: t('common.terms.campus'),
        value: data.Campus_name,
      },
    ],
    [data, t],
  );

  return <InfoTags tags={tags} />;
}
