import {
  CellFormatOptions,
  ColumnTable,
  DateInput,
  Icon,
  TextInput,
  TextOutlinedInput,
} from '@octano/global-ui';
import dayjs from 'dayjs';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { showDialogInfo } from '../../../components/Modals/Dialog';
import { PartialEvaluation, PartialEvaluationSubevaluations } from '../types';

export interface PartialEvaluationGradeColumnsProps {
  onChange: (state: PartialEvaluationSubevaluations) => void;
  error?: boolean;
  partialEvaluation: PartialEvaluationSubevaluations;
  partialEvaluationIndex: number;
}

export default function useSubevaluationGradeColumns({
  onChange,
  error,
  partialEvaluation,
  partialEvaluationIndex,
}: PartialEvaluationGradeColumnsProps) {
  const { t } = useTranslation();
  const isExisingtNameDuplicate = useCallback(
    (index: number, name: string) => {
      let errorText = partialEvaluation.grades?.find((grade, key) => {
        if (key !== index) {
          if (!grade.name) {
            return false;
          }
          return grade.name === name;
        }
        return false;
      });
      if (errorText) {
        return `*${t(
          'gradeEntry.partialEvaluationGradeEntry.duplicationName',
        )}`;
      }
      return undefined;
    },
    [partialEvaluation.grades, t],
  );

  const deletePartialEvaluationByIndex = useCallback(
    (row: { canItBeRemove: boolean }, data?: PartialEvaluation[]) => {
      return () => {
        console.log(row, data);
        if (!data) {
          return;
        }
        if (row.canItBeRemove) {
          const index = data.findIndex((g) => g === row);
          if (index >= 0) {
            data.splice(index, 1);
            partialEvaluation.grades = data;
            onChange(partialEvaluation);
          }
        } else {
          showDialogInfo({
            icon: 'warning',
            title: t('gradeEntry.partialEvaluationGradeEntry.dialogInfo.title'),
            subtitle: t(
              'gradeEntry.partialEvaluationGradeEntry.dialogInfo.subtitle',
            ),
          });
        }
      };
    },
    [t, partialEvaluation, onChange],
  );

  const onNameChange = useCallback(
    (row: { name?: string }) => {
      return (e: any) => {
        row.name = e.target.value;
        onChange(partialEvaluation);
      };
    },
    [partialEvaluation, onChange],
  );

  const onDateChange = useCallback(
    (row: { date?: string | Date | null }) => {
      return (value: any) => {
        if (value instanceof Date) {
          row.date = dayjs(value).format('YYYY-MM-DD');
        }
        row.date = value;
        onChange(partialEvaluation);
      };
    },
    [partialEvaluation, onChange],
  );

  const onWeightingChange = useCallback(
    (row: { percentage?: string }) => {
      return (e: any) => {
        let value = e.target.value;
        if (
          Number(value) <= 100 &&
          Number(value) >= 0 &&
          value.search(/\D/) === -1
        ) {
          row.percentage = value;
          onChange(partialEvaluation);
        }
      };
    },
    [partialEvaluation, onChange],
  );

  const columns = useMemo<ColumnTable[]>(() => {
    return [
      {
        columnName: 'evaluation',
        headerText: t(
          'gradeEntry.partialEvaluationGradeEntry.columns.evaluation',
        ),
        width: '160px',
        thClassName: 'text-center',
        cellFormat: ({ row, index }: CellFormatOptions<PartialEvaluation>) => {
          let errorText =
            error && !row.name ? t('gradeEntry.require') : undefined;
          let limitOfChar =
            row.name && row.name.length > 30
              ? t('gradeEntry.partialEvaluationGradeEntry.charLimit')
              : undefined;
          return (
            <TextInput
              key={index}
              name={`evaluation-${partialEvaluationIndex}-subevaluation-name-${
                index as number
              }`}
              label={t(
                'gradeEntry.partialEvaluationGradeEntry.columns.placeholder.nameEvaluation',
              )}
              type="text"
              value={row.name}
              errorText={
                isExisingtNameDuplicate(index as number, row.name as string) ||
                limitOfChar ||
                errorText
              }
              onChange={onNameChange(row)}
            />
          );
        },
      },
      {
        columnName: 'date',
        headerText: t('common.terms.date'),
        width: '160px',
        thClassName: 'text-center',
        cellFormat: (options: CellFormatOptions<PartialEvaluation>) => {
          let errorText =
            error && !options.row.date ? t('gradeEntry.require') : undefined;
          return (
            <DateInput
              name={`evaluation-${partialEvaluationIndex}-subevaluation-date-${
                options.index as number
              }`}
              label={t(
                'gradeEntry.partialEvaluationGradeEntry.columns.placeholder.surrencerDate',
              )}
              value={options.row.date}
              onChange={onDateChange(options.row)}
              shouldUnregister={true}
              errorText={errorText}
            />
          );
        },
      },
      {
        columnName: 'percentage',
        headerText: t(
          'gradeEntry.partialEvaluationGradeEntry.columns.percentage',
        ),
        width: '40px',
        thClassName: 'text-center',
        tdClassName: 'expander-height-input',
        cellFormat: (options: CellFormatOptions<PartialEvaluation>) => {
          let errorText =
            error && !options.row.percentage
              ? t('gradeEntry.require')
              : undefined;
          let value =
            options.row.percentage === '0' ? '' : options.row.percentage;
          return (
            <div className="d-flex align-items-center" style={{ gap: '8px' }}>
              <TextOutlinedInput
                type="text"
                name={`evaluation-${partialEvaluationIndex}-subevaluation-percentage-${
                  options.index as number
                }`}
                placeholder="—%"
                value={value}
                onChange={onWeightingChange(options.row)}
                errorText={errorText}
              />
              <div
                onClick={deletePartialEvaluationByIndex(
                  options.row,
                  options.data,
                )}
              >
                <Icon name="trash" className="mb-3" />
              </div>
            </div>
          );
        },
      },
    ];
  }, [
    t,
    partialEvaluationIndex,
    onNameChange,
    onDateChange,
    onWeightingChange,
    isExisingtNameDuplicate,
    deletePartialEvaluationByIndex,
    error,
  ]);

  return columns;
}
