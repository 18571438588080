import { ReactElement, useCallback, useEffect, useState } from 'react';

import {
  TeachaerSectionCourseOfGradeEntry,
  getSectionCourseOfGradeEntry,
} from '../../../api/requests/gradeEntry';
import DisplayError from '../../../components/Info/DisplayError';
import Loading from '../../../components/Info/Loading';

export interface AddEvaluationGradeEntryLoaderProps {
  sectionId: string;
  children: (props: TeachaerSectionCourseOfGradeEntry) => ReactElement;
}

export default function AddEvaluationGradeEntryLoader({
  sectionId,
  children,
}: AddEvaluationGradeEntryLoaderProps) {
  const [loading, setLoading] = useState<boolean>(true);

  const [data, setData] = useState<TeachaerSectionCourseOfGradeEntry>();
  const [error, setError] = useState<string | null>(null);

  /* Función que se llama cuando se monta el componente. Trae la info utilizada en la vista */
  const load = useCallback(async () => {
    setLoading(true);

    const response = await getSectionCourseOfGradeEntry(sectionId);

    if (response.data) {
      setData(response.data);
      setError(null);
    }
    if (response.error) {
      setError(response.error.code);
    }
    setLoading(false);
  }, [setLoading, sectionId]);

  useEffect(() => {
    load();
  }, [load]);

  if (error) {
    return (
      <DisplayError
        insideCard
        textBody={error}
        retryAction={load}
        loadingAction={loading}
      />
    );
  }

  if (loading) {
    return <Loading insideCard />;
  }

  if (!data) {
    return (
      <DisplayError
        insideCard
        textBody="Data no cargada"
        retryAction={load}
        loadingAction={loading}
      />
    );
  }

  return children(data);
}
