import { Section, SectionResponse } from '../types/Section';

/**
 * Convierte la respuesta de clases del dia en un modelo mas manejable del lado del front
 * @param classResData: Registro de clase proveniente del backend
 * @returns una instancia de Clase resumida
 */
export function selectSectionData(
  sectionResponseData: SectionResponse,
): Section {
  const { course, name, id } = sectionResponseData;

  return {
    id,
    sectionName: name,
    courseName: course.name,
    acronym: course.shortening,
  };
}
