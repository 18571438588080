import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import TableEmptyContent from '../../../components/Text/TableEmptyContent';
import { StudentProps } from './AddEvaluationGradeEntry';

export default function useSearchStudents() {
  const { t } = useTranslation();

  const [value, setValue] = useState<string>();

  const studentFilteredBySearch = useCallback(
    (students: StudentProps[]) => {
      if (!value || value.length < 3) return students;
      let key = value.split(' ');
      let studentsFiltered = students.filter(({ fullname, id }) => {
        let match = 0;
        key.forEach((v) => {
          if (
            fullname.toLocaleUpperCase().search(v.toLocaleUpperCase()) !== -1 ||
            id.toLocaleUpperCase().search(v.toLocaleUpperCase()) !== -1
          ) {
            match++;
          }
        });
        return match === key.length;
      });
      return studentsFiltered;
    },
    [value],
  );

  const onChangeSearch = useCallback((e: any) => {
    setValue(e.target.value);
  }, []);

  const onClearSearch = useCallback(() => {
    setValue('');
  }, []);

  const notResult = useMemo(() => {
    return (
      <TableEmptyContent
        title={t('gradeEntry.addEvaluation.tableNotResultInSearch.title')}
        subtitle={t('gradeEntry.addEvaluation.tableNotResultInSearch.subtitle')}
      />
    );
  }, [t]);

  return {
    value,
    onChangeSearch,
    studentFilteredBySearch,
    onClearSearch,
    notResult,
  };
}
